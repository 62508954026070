<template>
	<div class="acco-list">
		<a href="javascript:" class="acco-tit">
			<div class="form-checkbox">
				<input type="checkbox" id="Chk15" name="" @change="changeInvitationTransportationYn" :checked="subInvitation.invitationTransportationYn==='Y'">
				<label for="Chk15">교통수단</label>
			</div>
		</a>
		<div class="acco-con">
			<div class="acco-div">
				<section v-for="(invitationTransportation,index) in invitationTransportationJson" :key="index">
					<div class="acco-sec align-top">
						<p class="tit">교통수단</p>
						<div class="con">
							<div class="traffic-div form-input-ta">
								<div class="form-input w100p">
									<CustomFocusOutInput v-model="invitationTransportation.title" :placeholder="'제목을 입력하세요.'" @input="changeJsonInput"/>
								</div>
								<div class="form-textarea">
									<CustomFocusOutTextarea v-model="invitationTransportation.content" :placeholder="'내용입력'" @input="changeJsonInput"/>
								</div>
								<div class="btn-wrap">
									<a href="javascript:" @click="clickPrevTransportation(index)" class="prev"><img :src="`${publishpath}pub/images/icon-traffic-prev.svg`" alt=""></a>
									<a href="javascript:" @click="clickNextTransportation(index)" class="next"><img :src="`${publishpath}pub/images/icon-traffic-next.svg`" alt=""></a>
									<a href="javascript:" @click="clickDeleteTransportation(index)" class="delete"><img :src="`${publishpath}pub/images/icon-traffic-delete.svg`" alt=""></a>
								</div>
							</div>
						</div>
					</div>
				</section>
			</div>
			<section class="btn-flex-box">
				<a href="javascript:" @click="clickAddTransportation" class="white-btn"><span class="plus">교통수단 추가</span></a>
			</section>
		</div>
	</div>
</template>


<script>
import EventBus from "@/utils/CommonEventBus";
import CustomFocusOutInput from "@/components/common/CustomFocusOutInput.vue";
import CustomFocusOutTextarea from "@/components/common/CustomFocusOutTextarea.vue";
export default {
  name: 'WriteTransportation',
  components: {CustomFocusOutInput,CustomFocusOutTextarea},
  computed:{
  },
  data(){
    return {
		subInvitation:{},
		invitationTransportationJson:[{"title":"","content":""}]
    }
  },
  
  created(){
    
  },
  mounted(){
	const context = this
    EventBus.$on("invitationInit", (invitationInit) => {
		context.subInvitation =  invitationInit;
		context.invitationTransportationJson = JSON.parse(context.subInvitation.invitationTransportationJson)
	});
  },
  beforeDestroy(){
    EventBus.$off('invitationInit')
  },
  methods: {
	changeJsonInput(){
		this.subInvitation.invitationTransportationJson = JSON.stringify(this.invitationTransportationJson)
		this.$emit('changeData',this.subInvitation)
	},
	clickAddTransportation(){
		this.invitationTransportationJson.push({"title":"","content":""})
		this.changeJsonInput()
	},
	clickDeleteTransportation(index){
		this.invitationTransportationJson.splice(index,1)
		this.changeJsonInput()
	},
	clickPrevTransportation(index){
		if(index > 0){
			var item = this.invitationTransportationJson[index];
			this.invitationTransportationJson[index] = this.invitationTransportationJson[index-1];
			this.invitationTransportationJson[index-1] = item;
			this.changeJsonInput()
		}

	},
	clickNextTransportation(index){
		if(index < this.invitationTransportationJson.length-1){
			var item = this.invitationTransportationJson[index];
			this.invitationTransportationJson[index] = this.invitationTransportationJson[index+1];
			this.invitationTransportationJson[index+1] = item;
			this.changeJsonInput()
		}
	},
	changeInvitationTransportationYn(e){
		if(e.target.checked){
			this.subInvitation.invitationTransportationYn = 'Y'
		}else{

			this.subInvitation.invitationTransportationYn = 'N'
		}
		this.$emit('changeData',this.subInvitation)

	}

  },

}
</script>
