<template>
	<div class="acco-list">
		<a href="javascript:" class="acco-tit"><span>신랑/신부 정보</span></a>
		<div class="acco-con">
			<section>
				<div class="acco-sec">
					<p class="tit">신랑</p>
					<div class="con">
						<div class="form-flex-box">
							<div class="form-input w60">
								<CustomFocusOutInput v-model="subInvitation.invitationInfoGroomLastName" :placeholder="'성'" @input="changeInput"/>
							</div>
							<div class="form-input w60">
								<CustomFocusOutInput v-model="subInvitation.invitationInfoGroomFirstName" :placeholder="'이름'" @input="changeInput"/>
							</div>
							<div class="form-input w60">
								<CustomFocusOutInput v-model="subInvitation.invitationInfoGroomFamilyLevel" :placeholder="'관계'" @input="changeInput"/>
							</div>
						</div>
					</div>
				</div>
				<div class="acco-sec">
					<p class="tit">아버지</p>
					<div class="con">
						<div class="form-flex-box">
							<div class="form-input w130">
								<CustomFocusOutInput v-model="subInvitation.invitationInfoGroomFatherName" :placeholder="'성함'" @input="changeInput"/>
							</div>
							<div class="form-input w60">								
								<CustomFocusOutInput v-model="subInvitation.invitationInfoGroomFatherRelationship" :placeholder="'관계'" @input="changeInput"/>
							</div>
							<div class="form-checkbox">
								<input type="checkbox" id="Chk3" name="" @change="changeInvitationInfoGroomFatherDieYn" :checked="subInvitation.invitationInfoGroomFatherDieYn==='Y'">
								<label for="Chk3">故</label>
							</div>
						</div>
					</div>
				</div>
				<div class="acco-sec">
					<p class="tit">어머니</p>
					<div class="con">
						<div class="form-flex-box">
							<div class="form-flex-box">
								<div class="form-input w130">
									<CustomFocusOutInput v-model="subInvitation.invitationInfoGroomMotherName" :placeholder="'성함'" @input="changeInput"/>
								</div>
								<div class="form-input w60">
									<CustomFocusOutInput v-model="subInvitation.invitationInfoGroomMotherRelationship" :placeholder="'관계'" @input="changeInput"/>
								</div>
								<div class="form-checkbox">
									<input type="checkbox" id="Chk4" name="" @change="changeInvitationInfoGroomMotherDieYn" :checked="subInvitation.invitationInfoGroomMotherDieYn==='Y'">
									<label for="Chk4">故</label>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			<section>
				<div class="acco-sec">
					<p class="tit">신부</p>
					<div class="con">
						<div class="form-flex-box">
							<div class="form-input w60">
								<CustomFocusOutInput v-model="subInvitation.invitationInfoBrideLastName" :placeholder="'성'" @input="changeInput"/>
							</div>
							<div class="form-input w60">								
								<CustomFocusOutInput v-model="subInvitation.invitationInfoBrideFirstName" :placeholder="'이름'" @input="changeInput"/>
							</div>
							<div class="form-input w60">
								<CustomFocusOutInput v-model="subInvitation.invitationInfoBrideFamilyLevel" :placeholder="'관계'" @input="changeInput"/>
							</div>
						</div>
					</div>
				</div>
				<div class="acco-sec">
					<p class="tit">아버지</p>
					<div class="con">
						<div class="form-flex-box">
							<div class="form-input w130">									
								<CustomFocusOutInput v-model="subInvitation.invitationInfoBrideFatherName" :placeholder="'성함'" @input="changeInput"/>
							</div>
							<div class="form-input w60">
								<CustomFocusOutInput v-model="subInvitation.invitationInfoBrideFatherRelationship" :placeholder="'관계'" @input="changeInput"/>
							</div>
							<div class="form-checkbox">
								<input type="checkbox" id="Chk5" name="" @change="changeInvitationInfoBrideFatherDieYn" :checked="subInvitation.invitationInfoBrideFatherDieYn==='Y'">
								<label for="Chk5">故</label>
							</div>
						</div>
					</div>
				</div>
				<div class="acco-sec">
					<p class="tit">어머니</p>
					<div class="con">
						<div class="form-flex-box">
							<div class="form-flex-box">
								<div class="form-input w130">								
									<CustomFocusOutInput v-model="subInvitation.invitationInfoBrideMotherName" :placeholder="'성함'" @input="changeInput"/>
								</div>
								<div class="form-input w60">									
									<CustomFocusOutInput v-model="subInvitation.invitationInfoBrideMotherRelationship" :placeholder="'관계'" @input="changeInput"/>
								</div>
								<div class="form-checkbox">
									<input type="checkbox" id="Chk6" name="" @change="changeInvitationInfoBrideMotherDieYn" :checked="subInvitation.invitationInfoBrideMotherDieYn==='Y'">
									<label for="Chk6">故</label>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			<section>
				<div class="acco-sec">
					<p class="tit">故人 표기</p>
					<div class="con">
						<div class="form-checkbox">
							<input type="checkbox" id="Chk7" name="" @change="changeInvitationInfoDieFlowerYn" :checked="subInvitation.invitationInfoDieFlowerYn==='Y'">
							<label for="Chk7">국화 꽃으로 표기</label>
						</div>
					</div>
				</div>
				<div class="acco-sec">
					<p class="tit">항목 순서</p>
					<div class="con">
						<div class="form-checkbox">
							<input type="checkbox" id="Chk8" name="" @change="changeInvitationInfoBrideFirstYn" :checked="subInvitation.invitationInfoBrideFirstYn==='Y'">
							<label for="Chk8">신부측 먼저 표시</label>
						</div>
					</div>
				</div>
			</section>
			<div class="info-box">
				<p>아버지&middot;어머니 정보는 생략할 수 있습니다.</p>
			</div>
		</div>
	</div>
</template>


<script>
import EventBus from "@/utils/CommonEventBus";
import CustomFocusOutInput from "@/components/common/CustomFocusOutInput.vue";
export default {
  name: 'WriteGroomBrideInfo',
  props:[],
  components: {CustomFocusOutInput},
  computed:{
  },
  data(){
    return {
		subInvitation:{}
    }
  },
  
  created(){
    
  },
  mounted(){
	const context = this
    EventBus.$on("invitationInit", (invitationInit) => {
		context.subInvitation =  invitationInit;
	});
  },
  beforeDestroy(){
    EventBus.$off('invitationInit')
  },
  methods: {
	changeInput(){
		var invitationMainTextAndColorJson = JSON.parse(this.subInvitation.invitationMainTextAndColorJson)
		invitationMainTextAndColorJson.groomName = this.subInvitation.invitationInfoGroomLastName + this.subInvitation.invitationInfoGroomFirstName
		invitationMainTextAndColorJson.brideName = this.subInvitation.invitationInfoBrideLastName + this.subInvitation.invitationInfoBrideFirstName
		this.subInvitation.invitationKakaoShareThumbnailTitle = this.subInvitation.invitationInfoGroomLastName + this.subInvitation.invitationInfoGroomFirstName + ' ❤️ ' + this.subInvitation.invitationInfoBrideLastName + this.subInvitation.invitationInfoBrideFirstName+'의 결혼식';
		this.subInvitation.invitationUrlShareThumbnailTitle = this.subInvitation.invitationInfoGroomLastName + this.subInvitation.invitationInfoGroomFirstName + ' ❤️ ' + this.subInvitation.invitationInfoBrideLastName + this.subInvitation.invitationInfoBrideFirstName+'의 결혼식';

		
		this.subInvitation.invitationMainTextAndColorJson = JSON.stringify(invitationMainTextAndColorJson)
		this.$emit('changeData',this.subInvitation)
	},
	changeInvitationInfoGroomFatherDieYn(e){
		if(e.target.checked){
			this.subInvitation.invitationInfoGroomFatherDieYn = 'Y'
		}else{

			this.subInvitation.invitationInfoGroomFatherDieYn = 'N'
		}
		this.$emit('changeData',this.subInvitation)

	},
	changeInvitationInfoGroomMotherDieYn(e){
		if(e.target.checked){
			this.subInvitation.invitationInfoGroomMotherDieYn = 'Y'
		}else{

			this.subInvitation.invitationInfoGroomMotherDieYn = 'N'
		}
		this.$emit('changeData',this.subInvitation)

	},
	changeInvitationInfoBrideFatherDieYn(e){
		if(e.target.checked){
			this.subInvitation.invitationInfoBrideFatherDieYn = 'Y'
		}else{

			this.subInvitation.invitationInfoBrideFatherDieYn = 'N'
		}
		this.$emit('changeData',this.subInvitation)

	},
	changeInvitationInfoBrideMotherDieYn(e){
		if(e.target.checked){
			this.subInvitation.invitationInfoBrideMotherDieYn = 'Y'
		}else{

			this.subInvitation.invitationInfoBrideMotherDieYn = 'N'
		}
		this.$emit('changeData',this.subInvitation)

	},
	changeInvitationInfoDieFlowerYn(e){
		if(e.target.checked){
			this.subInvitation.invitationInfoDieFlowerYn = 'Y'
		}else{

			this.subInvitation.invitationInfoDieFlowerYn = 'N'
		}
		this.$emit('changeData',this.subInvitation)
	},
	changeInvitationInfoBrideFirstYn(e){
		if(e.target.checked){
			this.subInvitation.invitationInfoBrideFirstYn = 'Y'
		}else{

			this.subInvitation.invitationInfoBrideFirstYn = 'N'
		}
		this.$emit('changeData',this.subInvitation)
	}

  },

}
</script>
