<template>
	<div class="acco-list">
		<a href="javascript:" class="acco-tit"><span>메인화면</span></a>
		<div class="acco-con no-padding">
			<div class="main-view-tab-area">
				<div class="tab-con-w">
					<div :class="['tab-con',subInvitation.invitationMainType==='basic'?'on':'']">
						<button type="button" :class="['type1', 'basic',subInvitation.invitationMainSubType==='type1'? 'on':'']" @click="clickInvitationMainSubType('type1')">
							<img :src="`${publishpath}pub/images/img-main-basic-type1.png`" alt="">
						</button>
						<button type="button" :class="['type2', 'basic',subInvitation.invitationMainSubType==='type2'? 'on':'']" @click="clickInvitationMainSubType('type2')">
							<img :src="`${publishpath}pub/images/img-main-basic-type2.png`" alt="">
						</button>
						<button type="button" :class="['type3', 'basic',subInvitation.invitationMainSubType==='type3'? 'on':'']" @click="clickInvitationMainSubType('type3')">
							<img :src="`${publishpath}pub/images/img-main-basic-type3.png`" alt="">
						</button>
						<button type="button" :class="['type4', 'basic',subInvitation.invitationMainSubType==='type4'? 'on':'']" @click="clickInvitationMainSubType('type4')">
							<img :src="`${publishpath}pub/images/img-main-basic-type4.png`" alt="">
						</button>
						<button type="button" :class="['type5', 'basic',subInvitation.invitationMainSubType==='type5'? 'on':'']" @click="clickInvitationMainSubType('type5')">
							<img :src="`${publishpath}pub/images/img-main-basic-type5.png`" alt="">
						</button>
					</div>
					<div :class="['tab-con',subInvitation.invitationMainType==='arch'?'on':'']">
						<button type="button" :class="['type1', 'arch',subInvitation.invitationMainSubType==='type1'? 'on':'']" @click="clickInvitationMainSubType('type1')">
							<img :src="`${publishpath}pub/images/img-main-arch-type1.png`" alt="">
						</button>
						<button type="button" :class="['type2', 'arch',subInvitation.invitationMainSubType==='type2'? 'on':'']" @click="clickInvitationMainSubType('type2')">
							<img :src="`${publishpath}pub/images/img-main-arch-type2.png`" alt="">
						</button>
						<button type="button" :class="['type3', 'arch',subInvitation.invitationMainSubType==='type3'? 'on':'']" @click="clickInvitationMainSubType('type3')">
							<img :src="`${publishpath}pub/images/img-main-arch-type3.png`" alt="">
						</button>
						<button type="button" :class="['type5', 'arch',subInvitation.invitationMainSubType==='type5'? 'on':'']" @click="clickInvitationMainSubType('type5')">
							<img :src="`${publishpath}pub/images/img-main-arch-type5.png`" alt="">
						</button>
					</div>
					<div :class="['tab-con',subInvitation.invitationMainType==='fill'?'on':'']">
						<button type="button" :class="['type1', 'fill',subInvitation.invitationMainSubType==='type1'? 'on':'']" @click="clickInvitationMainSubType('type1')">
							<img :src="`${publishpath}pub/images/img-main-fill-type1.png`" alt="">
						</button>
						<button type="button" :class="['type2', 'fill',subInvitation.invitationMainSubType==='type2'? 'on':'']" @click="clickInvitationMainSubType('type2')">
							<img :src="`${publishpath}pub/images/img-main-fill-type2.png`" alt="">
						</button>
						<button type="button" :class="['type3', 'fill',subInvitation.invitationMainSubType==='type3'? 'on':'']" @click="clickInvitationMainSubType('type3')">
							<img :src="`${publishpath}pub/images/img-main-fill-type3.png`" alt="">
						</button>
						<button type="button" :class="['type5', 'fill',subInvitation.invitationMainSubType==='type5'? 'on':'']" @click="clickInvitationMainSubType('type5')">
							<img :src="`${publishpath}pub/images/img-main-fill-type5.png`" alt="">
						</button>
					</div>
				</div>
				<div class="tab-btn-w">
					<button type="button" :class="['tab-btn',subInvitation.invitationMainType==='basic'?'on':'']" @click="clickInvitationMainType('basic')"><span>기본</span></button>
					<button type="button" :class="['tab-btn',subInvitation.invitationMainType==='arch'?'on':'']" @click="clickInvitationMainType('arch')"><span>아치형</span></button>
					<button type="button" :class="['tab-btn',subInvitation.invitationMainType==='fill'?'on':'']" @click="clickInvitationMainType('fill')"><span>채우기</span></button>
				</div>
			</div>
			<div class="main-view-con">
				<div class="acco-sec align-top">
					<p class="tit">사진</p>
					<div class="con">
					<div class="img-upload-w">
						<!-- 이미지 등록 전 영역 -->
						<CustomInputFile :type='"main"' @uploadFile="uploadMainFile" :cropRation="mainCropRation" :existFileUrl="subInvitation.invitationMainImageFile === null?null:eixstIdMakeDisplayUrl(subInvitation.invitationMainImageFile)"/>
						<!-- // 이미지 등록 전 영역 -->
						<!-- 이미지 등록 후 영역 -->
						<!-- <div class="file-img-area">
							<img :src="`${publishpath}pub/images/@img-upload.jpg`" alt="">
							<button type="button" class="delete-btn"></button>
						</div> -->
						<!-- // 이미지 등록 후 영역 -->
					</div>
					</div>
				</div>
				<div class="acco-sec" v-if="false">
					<p class="tit">이펙트</p><!--사용안함-->
					<div class="con">
						<div class="border-tab-btn">
							<button type="button" class="on"><span>없음</span></button>
							<button type="button"><span>텍스트</span></button>
							<button type="button"><span>텍스트</span></button>
							<button type="button"><span>텍스트</span></button>
						</div>
					</div>
				</div>
				<div class="acco-sec-w">
					<div class="acco-sec">
						<p class="tit">텍스트 편집</p>
						<div class="con">
							<a href="javascript:" class="dropdown-btn"><span>텍스트 및 컬러 편집하기</span></a>
						</div>
					</div>
					<div class="dropdown-con">
						<div :class="['con-div',subInvitation.invitationMainSubType==='type1'? 'on':'']" >
							<div class="form-flex-box">
								<div class="checkbox-div"></div>
								<a href="javascript:" class="color">
									<span>기본컬러</span>
								</a>
								<div class="form-input-w">
									<div class="form-input w100p">
										<input type="text" disabled value="Save the Date / 예식날짜">
									</div>
								</div>
							</div>
							<div class="form-flex-box">
								<div class="checkbox-div">
									<div class="form-checkbox no-txt">
										<input type="checkbox" id="Chk34" name="" @change="changeNameCheckYn" :checked="invitationMainTextAndColorJson.nameCheckYn==='Y'">
										<label for="Chk34"></label>
									</div>
								</div>
								<CustomColorSelect  :invitation="subInvitation" :type="'nameColor'" @changeData="changeData"/>
								<div class="form-input-w">
									<div class="form-input w100p">
										<CustomFocusOutInput v-model="invitationMainTextAndColorJson.groomName" :placeholder="'이름'" @input="changeInput"/>
									</div>
									<div class="form-input w100p">
										<CustomFocusOutInput v-model="invitationMainTextAndColorJson.brideName" :placeholder="'이름'" @input="changeInput"/>
									</div>
								</div>
							</div>
							<div class="form-flex-box">
								<div class="checkbox-div">
									<div class="form-checkbox no-txt">
										<input type="checkbox" id="Chk35" name="" @change="changeAddressAndTimeCheckYn" :checked="invitationMainTextAndColorJson.addressAndTimeCheckYn==='Y'">
										<label for="Chk35"></label>
									</div>
								</div>
								<CustomColorSelect  :invitation="subInvitation" :type="'addressAndTimeColor'" @changeData="changeData"/>
								<div class="form-input-w">
									<div class="form-input w100p">
										<CustomFocusOutInput v-model="invitationMainTextAndColorJson.addressAndTime" :placeholder="'예식일시, 장소'" @input="changeInput"/>
									</div>
								</div>
							</div>
						</div>
						<div :class="['con-div',subInvitation.invitationMainSubType==='type2'? 'on':'']" >
							<div class="form-flex-box">
								<div class="checkbox-div">
									<div class="form-checkbox no-txt">
										<input type="checkbox" id="Chk36" name=""  @change="changeNameCheckYn" :checked="invitationMainTextAndColorJson.nameCheckYn==='Y'">
										<label for="Chk36"></label>
									</div>
								</div>
								<CustomColorSelect  :invitation="subInvitation" :type="'nameColor'" @changeData="changeData"/>
								<div class="form-input-w">
									<div class="form-input w100p">
										<CustomFocusOutInput v-model="invitationMainTextAndColorJson.groomName" :placeholder="'이름'" @input="changeInput"/>
									</div>
									<div class="form-input w100p">
										<CustomFocusOutInput v-model="invitationMainTextAndColorJson.and" :placeholder="'그리고'" @input="changeInput"/>
									</div>
									<div class="form-input w100p">
										<CustomFocusOutInput v-model="invitationMainTextAndColorJson.brideName" :placeholder="'이름'" @input="changeInput"/>
									</div>
								</div>
							</div>
							<div class="form-flex-box">
								<div class="checkbox-div">
									<div class="form-checkbox no-txt">
										<input type="checkbox" id="Chk37" name=""  @change="changeWelcomeCheckYn" :checked="invitationMainTextAndColorJson.welcomeCheckYn==='Y'">
										<label for="Chk37"></label>
									</div>
								</div>
								<CustomColorSelect  :invitation="subInvitation" :type="'welcomeColor'" @changeData="changeData"/>
								<div class="form-input-w">
									<div class="form-input w100p">
										<CustomFocusOutInput v-model="invitationMainTextAndColorJson.welcome" :placeholder="'두 사람, 그리고 결혼합니다.'" @input="changeInput"/>
									</div>
								</div>
							</div>
							<div class="form-flex-box">
								<div class="checkbox-div">
									<div class="form-checkbox no-txt">
										<input type="checkbox" id="Chk38" name="" @change="changeAddressAndTimeCheckYn" :checked="invitationMainTextAndColorJson.addressAndTimeCheckYn==='Y'">
										<label for="Chk38"></label>
									</div>
								</div>
								<CustomColorSelect  :invitation="subInvitation" :type="'addressAndTimeColor'" @changeData="changeData"/>
								<div class="form-input-w">
									<div class="form-input w100p">										
										<CustomFocusOutInput v-model="invitationMainTextAndColorJson.addressAndTime" :placeholder="'예식일시, 장소'" @input="changeInput"/>
									</div>
								</div>
							</div>
						</div>
						<div :class="['con-div',subInvitation.invitationMainSubType==='type3'? 'on':'']">
							<div class="form-flex-box">
								<div class="checkbox-div">
									<div class="form-checkbox no-txt">
										<input type="checkbox" id="Chk39" name=""  @change="changeWelcomeCheckYn" :checked="invitationMainTextAndColorJson.welcomeCheckYn==='Y'">
										<label for="Chk39"></label>
									</div>
								</div>
								<CustomColorSelect  :invitation="subInvitation" :type="'welcomeColor'" @changeData="changeData"/>
								<div class="form-input-w">
									<div class="form-input w100p">
										<CustomFocusOutInput v-model="invitationMainTextAndColorJson.welcome" :placeholder="'The marriage of'" @input="changeInput"/>
									</div>
								</div>
							</div>
							<div class="form-flex-box">
								<div class="checkbox-div">
									<div class="form-checkbox no-txt">
										<input type="checkbox" id="Chk40" name="" @change="changeNameCheckYn" :checked="invitationMainTextAndColorJson.nameCheckYn==='Y'">
										<label for="Chk40"></label>
									</div>
								</div>
								<CustomColorSelect  :invitation="subInvitation" :type="'nameColor'" @changeData="changeData"/>
								<div class="form-input-w">
									<div class="form-input w100p">										
										<CustomFocusOutInput v-model="invitationMainTextAndColorJson.groomName" :placeholder="'이름'" @input="changeInput"/>
									</div>
									<div class="form-input w100p">
										<CustomFocusOutInput v-model="invitationMainTextAndColorJson.and" :placeholder="'&'" @input="changeInput"/>
									</div>
									<div class="form-input w100p">
										<CustomFocusOutInput v-model="invitationMainTextAndColorJson.brideName" :placeholder="'이름'" @input="changeInput"/>
									</div>
								</div>
							</div>
							<div class="form-flex-box">
								<div class="checkbox-div">
									<div class="form-checkbox no-txt">
										<input type="checkbox" id="Chk41" name="" @change="changeDateCheckYn" :checked="invitationMainTextAndColorJson.dateCheckYn==='Y'">
										<label for="Chk41"></label>
									</div>
								</div>
								<CustomColorSelect  :invitation="subInvitation" :type="'dateColor'" @changeData="changeData"/>
								<div class="form-input-w">
									<div class="form-input w100p">
										<CustomFocusOutInput v-model="invitationMainTextAndColorJson.date" :placeholder="'Save the Date'" @input="changeInput"/>
									</div>
								</div>
							</div>
							<div class="form-flex-box">
								<div class="checkbox-div">
									<div class="form-checkbox no-txt">
										<input type="checkbox" id="Chk42" name="" @change="changeAddressAndTimeCheckYn" :checked="invitationMainTextAndColorJson.addressAndTimeCheckYn==='Y'">
										<label for="Chk42"></label>
									</div>
								</div>
								<CustomColorSelect  :invitation="subInvitation" :type="'addressAndTimeColor'" @changeData="changeData"/>
								<div class="form-input-w">
									<div class="form-input w100p">
										<CustomFocusOutInput v-model="invitationMainTextAndColorJson.addressAndTime" :placeholder="'예식일시, 장소'" @input="changeInput"/>
									</div>
								</div>
							</div>
						</div>
						<div :class="['con-div',subInvitation.invitationMainSubType==='type4'? 'on':'']">
							<div class="form-flex-box">
								<div class="checkbox-div">
									<div class="form-checkbox no-txt">
										<input type="checkbox" id="Chk43" name="" @change="changeNameCheckYn" :checked="invitationMainTextAndColorJson.nameCheckYn==='Y'">
										<label for="Chk43"></label>
									</div>
								</div>
								<CustomColorSelect  :invitation="subInvitation" :type="'nameColor'" @changeData="changeData"/>
								<div class="form-input-w">
									<div class="form-input w100p">
										<CustomFocusOutInput v-model="invitationMainTextAndColorJson.groomName" :placeholder="'이름'" @input="changeInput"/>
									</div>
									<div class="form-input w100p">
										<CustomFocusOutInput v-model="invitationMainTextAndColorJson.brideName" :placeholder="'이름'" @input="changeInput"/>
									</div>
								</div>
							</div>
							<div class="form-flex-box">
								<div class="checkbox-div">
									<div class="form-checkbox no-txt">
										<input type="checkbox" id="Chk44" name="" @change="changeWelcomeCheckYn" :checked="invitationMainTextAndColorJson.welcomeCheckYn==='Y'">
										<label for="Chk44"></label>
									</div>
								</div>
								<CustomColorSelect  :invitation="subInvitation" :type="'welcomeColor'" @changeData="changeData"/>
								<div class="form-input-w">
									<div class="form-input w100p">
										<CustomFocusOutInput v-model="invitationMainTextAndColorJson.welcome" :placeholder="'0월 0일, 저희 결혼합니다.'" @input="changeInput"/>
									</div>
								</div>
							</div>
							<div class="form-flex-box">
								<div class="checkbox-div">
									<div class="form-checkbox no-txt">
										<input type="checkbox" id="Chk45" name="" @change="changeAddressAndTimeCheckYn" :checked="invitationMainTextAndColorJson.addressAndTimeCheckYn==='Y'">
										<label for="Chk45"></label>
									</div>
								</div>
								<CustomColorSelect  :invitation="subInvitation" :type="'addressAndTimeColor'" @changeData="changeData"/>
								<div class="form-input-w">
									<div class="form-input w100p">
										<CustomFocusOutInput v-model="invitationMainTextAndColorJson.addressAndTime" :placeholder="'예식일시, 장소'" @input="changeInput"/>
									</div>
								</div>
							</div>
						</div>
						<div :class="['con-div',subInvitation.invitationMainSubType==='type5'? 'on':'']">
							<div class="form-flex-box">
								<div class="checkbox-div">
									<div class="form-checkbox no-txt">
										<input type="checkbox" id="Chk46" name="" @change="changeWelcomeCheckYn" :checked="invitationMainTextAndColorJson.welcomeCheckYn==='Y'">
										<label for="Chk46"></label>
									</div>
								</div>
								<CustomColorSelect  :invitation="subInvitation" :type="'welcomeColor'" @changeData="changeData"/>
								<div class="form-input-w">
									<div class="form-input w100p">
										<CustomFocusOutInput v-model="invitationMainTextAndColorJson.welcome" :placeholder="'OUR WEDDING DAY'" @input="changeInput"/>
									</div>
								</div>
							</div>
							<div class="form-flex-box">
								<div class="checkbox-div"></div>
								<a href="javascript:" class="color"><span>기본컬러</span></a>
								<div class="form-input-w">
									<div class="form-input w100p">
										<input type="text" disabled placeholder="예식날짜">
									</div>
								</div>
							</div>
							<div class="form-flex-box">
								<div class="checkbox-div">
									<div class="form-checkbox no-txt">
										<input type="checkbox" id="Chk47" name="" @change="changeAddressAndTimeCheckYn" :checked="invitationMainTextAndColorJson.addressAndTimeCheckYn==='Y'">
										<label for="Chk47"></label>
									</div>
								</div>
								<CustomColorSelect  :invitation="subInvitation" :type="'addressAndTimeColor'" @changeData="changeData"/>
								<div class="form-input-w">
									<div class="form-input w100p">
										<CustomFocusOutInput v-model="invitationMainTextAndColorJson.addressAndTime" :placeholder="'예식일시, 장소'" @input="changeInput"/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>


<script>
import EventBus from "@/utils/CommonEventBus";
import CustomColorSelect from "@/components/common/CustomColorSelect.vue";
import CustomFocusOutInput from "@/components/common/CustomFocusOutInput.vue";
import CustomInputFile from "@/components/common/CustomInputFile.vue";
export default {
  name: 'WriteMain',
  props:['invitation'],
    watch : {
		invitation() {
            
        }
    },
  components: {CustomFocusOutInput,CustomInputFile,CustomColorSelect },
  computed:{
  },
  data(){
    return {
		subInvitation:{},
		invitationMainTextAndColorJson:{},
		mainCropRation:{
			x:3,
			y:4
		}
    }
  },
  
  created(){
    
  },
  mounted(){
	const context = this
    EventBus.$on("invitationInit", (invitationInit) => {
		context.subInvitation =  invitationInit;
		context.invitationMainTextAndColorJson = JSON.parse(context.subInvitation.invitationMainTextAndColorJson)
	});
  },
  beforeDestroy(){
    EventBus.$off('invitationInit')
  },
  methods: {
	changeInput(){
		this.subInvitation.invitationMainTextAndColorJson = JSON.stringify(this.invitationMainTextAndColorJson)
		this.$emit('changeData',this.subInvitation)
	},
	clickInvitationMainType(data){
		this.subInvitation.invitationMainType = data
		this.subInvitation.invitationMainSubType = 'type1'
		
		this.$emit('changeData',this.subInvitation)

	},
	clickInvitationMainSubType(data){
		this.subInvitation.invitationMainSubType = data
		
		this.$emit('changeData',this.subInvitation)

	},
	changeData(data){
		this.$emit('changeData',data)
	},
	changeNameCheckYn(e){
		if(e.target.checked){
			this.invitationMainTextAndColorJson.nameCheckYn = 'Y'
		}else{

			this.invitationMainTextAndColorJson.nameCheckYn = 'N'
		}
		this.changeInput()

	},
	changeAddressAndTimeCheckYn(e){
		if(e.target.checked){
			this.invitationMainTextAndColorJson.addressAndTimeCheckYn = 'Y'
		}else{

			this.invitationMainTextAndColorJson.addressAndTimeCheckYn = 'N'
		}

		this.changeInput()

	},
	changeWelcomeCheckYn(e){
		if(e.target.checked){
			this.invitationMainTextAndColorJson.welcomeCheckYn = 'Y'
		}else{

			this.invitationMainTextAndColorJson.welcomeCheckYn = 'N'
			this.invitationMainTextAndColorJson.welcome = null
		}

		this.changeInput()

	},
	changeDateCheckYn(e){
		if(e.target.checked){
			this.invitationMainTextAndColorJson.dateCheckYn = 'Y'
		}else{

			this.invitationMainTextAndColorJson.dateCheckYn = 'N'
			this.invitationMainTextAndColorJson.date = null
		}

		this.changeInput()

	},
	uploadMainFile(param){

		
		this.subInvitation.invitationMainImageFile = param

		
		this.changeInput()

	}

  },

}
</script>
