<template>
	<div class="file-btn-area">
		<input type="file" :id="type" multiple  @change="onFileChange" accept="image/gif,image/jpeg,image/png"/>
		<label :for="type"><span>사진 추가</span></label>
	</div>
</template>
<script>
import imageCompression from 'browser-image-compression';
import EventBus from "@/utils/CommonEventBus";
import { Message } from 'element-ui'
export default {
    name: 'CustomInputFile',
    props:['type','subtype','currentCount'],
    data() {
        return{
        }
    },
    mounted(){
        const context = this
        EventBus.$on("popupEndCrops", (param) => {
            if(context.type === param.type){
                const files = []
                const beforeFiles = []
                for(var i=0;i<param.files.length;i++){
                    files.push( 
                        {
                            beforeFile: param.beforeFiles[i],
                            file: param.files[i],
                            url: URL.createObjectURL(param.files[i]),
                            fileValue1:param.fileValue1[i],
                            selectedIndex: param.selectedIndex
                        }
                    )
                    beforeFiles.push( 
                        {
                            file: param.beforeFiles[i],
                            url: URL.createObjectURL(param.beforeFiles[i]),
                            fileValue1:param.fileValue1[i],
                            selectedIndex: param.selectedIndex
                        }
                    )
                }
                const parameter = {
                    files : files,
                    beforeFiles: beforeFiles
                }
                context.$emit('uploadFile',parameter);

            }
        });

    },
    beforeDestroy(){
        EventBus.$off('popupEndCrops');
    },
	methods: {
        async onFileChange(e){
            this.$store.commit('START_LOADING');
            let fileParam = []
            try{
                if(Number(this.currentCount)+e.target.files.length>30){
                    this.warningMessage('갤러리 사진은 30개까지 등록 가능합니다.');
                    return;
                }
                if(e.target.files.length>0){

                    for(var i=0;i<e.target.files.length;i++){
                        let file = e.target.files[i];

                        // 이미지 resize 옵션 설정 (최대 width을 100px로 지정)
                        const options = { 
                            maxSizeMB: 0.2,
                        }
                        
                        try {
                            const compressedFile = await imageCompression(file, options);
                            fileParam.push(compressedFile)
                        } catch (error) {
                            this.warningMessage('사진 압축에 실패하였습니다. 잠시후 다시 시도해 주세요.');
                            fileParam=[];
                            console.log(error);
                            break;
                        }
                    }

                    
                    if(fileParam.length>0){
                        const param = {
                            flag: 'imagesCropPop',
                            files:fileParam,
                            type:this.type,
                            subtype: this.subtype,
                        }
                        EventBus.$emit('layerPopup',param)
                    }
                        
                    

                }   
            } catch (error) {
				this.warningMessage('사진 압축에 실패하였습니다. 잠시후 다시 시도해 주세요.');
                fileParam=[];
				console.log(error);
			}
            this.$store.commit('END_LOADING');

        },
        
        deleteFile(){
            this.$emit('uploadFile',null);
        }
    }
}
</script>