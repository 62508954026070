<template>
  <div class="cont-wrap">
		<div class="inner">
      		<div class="theme-area">
					<div class="preview-area" >
						<template v-if="getPreviewFlag">
							<div class="top-area">
								<p class="tit">모바일청첩장 미리보기</p>
								<button type="button" class="close-btn" @click="clickCloseBtn"></button>
							</div>
						</template>
						<b  v-show="getFullScreenFlag || getPreviewFlag">
							<div class="fixed-area">
								<WritePreview :invitation="invitation" :changeKey="changeKey"/>
							</div>
						</b>
					</div>
				<div class="acco-area">
					<WriteThema @changeData="changeData"/>
					<WriteGroomBrideInfo  @changeData="changeData"/>
					<WriteMain  @changeData="changeData" :invitation="invitation"/>
					<WriteGreetingWord  @changeData="changeData"/>
					<WriteTime  @changeData="changeData"/>
					<WriteAddress  @changeData="changeData"/>
					<WriteTransportation @changeData="changeData"/>
					<WriteGallery @changeData="changeData"  :changeKey="changeKey"/>
					<WriteContact @changeData="changeData"/>
					<WriteAccount @changeData="changeData"/>
					<WriteYoutube @changeData="changeData"/>
					<WriteGuestBook @changeData="changeData"/>
					<WriteEnd @changeData="changeData"/>
					<WriteAttend @changeData="changeData"/>
					<WriteKakaoShareThumbnail @changeData="changeData"/>
					<WriteUrlShareThumbnail @changeData="changeData"/>
					<WriteLayoutSort  @changeData="changeData" />
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters } from "vuex";
import WritePreview from "@/components/write/preview/WritePreview.vue";
import WriteThema from "@/components/write/thema/WriteThema.vue";
import WriteGroomBrideInfo from "@/components/write/groombrideinfo/WriteGroomBrideInfo.vue";
import WriteMain from "@/components/write/main/WriteMain.vue";
import WriteGreetingWord from "@/components/write/greetingword/WriteGreetingWord.vue";
import WriteTime from "@/components/write/time/WriteTime.vue";
import WriteAddress from "@/components/write/address/WriteAddress.vue";
import WriteTransportation from "@/components/write/transportation/WriteTransportation.vue";
import WriteGallery from "@/components/write/gallery/WriteGallery.vue";
import WriteContact from "@/components/write/contact/WriteContact.vue";
import WriteAccount from "@/components/write/account/WriteAccount.vue";
import WriteYoutube from "@/components/write/youtube/WriteYoutube.vue";
import WriteGuestBook from "@/components/write/guestbook/WriteGuestBook.vue";
import WriteEnd from "@/components/write/end/WriteEnd.vue";
import WriteAttend from "@/components/write/attend/WriteAttend.vue";
import WriteKakaoShareThumbnail from "@/components/write/kakaosharethumbnail/WriteKakaoShareThumbnail.vue";
import WriteUrlShareThumbnail from "@/components/write/urlsharethumbnail/WriteUrlShareThumbnail.vue";
import WriteLayoutSort from "@/components/write/layoutsort/WriteLayoutSort.vue";


import EventBus from "@/utils/CommonEventBus";
const defaultInvitation = () => {
  return {
			invitationId:null,
			invitationThemeFont :'jejumyeongjo', // 글꼴 jejumyeongjo, nanumsquareneo, kimjungchulmyungjo, bookkmyungjo
			invitationThemeFontSize :'none', // 글자크기 보통 - none size1 - 크게, size2 - 더 크게
			invitationThemeColor : 'gray', // 색상 gray, pink, yellow, green
			invitationThemeExpansionYn :'N', // 확대방지사용유무
			invitationThemeAppearYn : 'N', // 등장효과사용유무
			invitationInfoGroomLastName :'', // 신랑성
			invitationInfoGroomFirstName :'', // 신랑이름
			invitationInfoGroomFamilyLevel :'아들', // 신랑 가족에서 순서
			invitationInfoGroomFatherName :'', // 신랑아버지이름
			invitationInfoGroomFatherRelationship :'아버지', // 신랑아버지관계
			invitationInfoGroomFatherDieYn : 'N', // 신랑아버지고인유무
			invitationInfoGroomMotherName :'', // 신랑어머니이름
			invitationInfoGroomMotherRelationship :'어머니', // 신랑어머니관계
			invitationInfoGroomMotherDieYn : 'N', // 신랑어머니고인유무
			invitationInfoBrideLastName :'', // 신부성
			invitationInfoBrideFirstName :'', // 신부이름
			invitationInfoBrideFamilyLevel :'딸', // 신부가족에서 순서
			invitationInfoBrideFatherName :'', // 신부아버지이름
			invitationInfoBrideFatherRelationship :'아버지', // 신부아버지관계
			invitationInfoBrideFatherDieYn : 'N', // 신부아버지고인유무
			invitationInfoBrideMotherName :'', // 신부어머니이름
			invitationInfoBrideMotherRelationship :'어머니', // 신부어머니관계
			invitationInfoBrideMotherDieYn : 'N', // 신부어머니고인유무
			invitationInfoDieFlowerYn : 'N', // 고인국화꽃으로표기유무
			invitationInfoBrideFirstYn : 'N', // 신부측 먼저 표시 유무
			invitationMainType :'basic', // 메인화면디자인타입 ** 메인 화면 basic, arch, fill 3가지 타입 **
			invitationMainSubType :'type1', // 메인화면디자인타입 ** type1, 2, 3, 4, 5 존재 (arch는 4가 없음) **
			invitationMainEfect :null, // 메인화면이팩트타입
			invitationMainTextAndColorJson :'{"groomName":"","and":"","brideName":"","nameColor":"", "nameCheckYn":"N","addressAndTime":"","addressAndTimeCheckYn":"N","addressAndTimeColor":"","welcome":"","welcomeCheckYn":"N","welcomeColor":"","date":"","dateCheckYn":"","dateColor":""}', // 메이화면텍스트 및 컬러 설정 json data\
			invitationGreetingWordTitle :'초대합니다', // 인산말제목
			invitationGreetingWordContent :'살랑이는 바람결에 \n사랑이 묻어나는 계절입니다. \n여기 곱고 예쁜 두 사람이 사랑을 맺어 \n인생의 반려자가 되려 합니다. \n새 인생을 시작하는 이 자리에 오셔서 \n축복해 주시면 감사하겠습니다.', // 인사말내용
			invitationGreetingNameVisibleYn : 'Y', // 인사말하단에신랑신부혼주성함표시유무
			invitationTimeDatetime :'2099-01-01 11:30:00', // 웨딩일시
			invitationTimeCalendarYn : 'Y', // 캘린더표시유무
			invitationTimeDdayYn : 'Y', // 디데이표시유무
			invitationAddressTitle :'오시는 길', // 예식장소타이틀
			invitationAddressBuildingName :'', // 예식장명
			invitationAddressBuildingFloor:'',//예식장 층과홀
			invitationAddressBuildingNumber :'', // 예식장연락처
			invitationAddress :'', // 주소
			invitationAddressX :'', // 예식장주소X좌표
			invitationAddressY :'', // 예식장주소Y좌표
			invitationAddressMapYn : 'Y', // 예식장지도표기유무
			invitationAddressExtendsYn : 'Y', // 지도 터치 및 줌 조정 안되도록
			invitationAddressNavigationBtnYn : 'Y', // 예식장지도네비게이션버튼표기유무
			invitationTransportationYn : 'Y', // 교통수단사용유무
			invitationTransportationJson :'[{"title":"","content":""}]', // 교통수단 JSON data [{"title":"자가용","content":"내용"}]
			invitationGalleryYn : 'Y', // 갤러리사용유무
			invitationGalleryTitle :'갤러리', // 갤러리 타이틀
			invitationGalleryType :'basic', // 갤러리타입 basic, thumbnail, grid
			invitationGalleryTypeArray :'2', // 갤러리그리드 일경우에만 2, 3
			invitationGalleryTypeRatio :'complex', // 갤러리그리드 일경우에만 complex, square, long
			invitationGalleryComplexMoreYn :'Y', // 갤러리그리드 일경우에만 더보기버튼 유무
			invitationContactYn : 'Y', // 연락처사용유무
			invitationContactGroomNumber :'', // 신랑번호
			invitationContactGroomFatherNumber :'', // 신랑아버지번호
			invitationContactGroomMotherNumber :'', // 신랑어머니번호
			invitationContactBrideNumber :'', // 신부번호
			invitationContactBrideFatherNumber :'', // 신부아버지번호
			invitationContactBrideMotherNumber :'', // 신부어머니번호
			invitationAccountYn : 'Y', // 계좌번호사용유무
			invitationAccountTitle :'마음 전하실 곳', // 계좌번호제목
			invitationAccountContent :'참석이 어려우신 분들을 위해 \n계좌번호를 기재하였습니다. \n너그러운 마음으로 양해 부탁드립니다.', // 계좌번호내용
			invitationAccountGroomGroupName :'신랑측 계좌번호', // 신랑계좌번호그룹명
			invitationAccountGroomBank :'', // 신랑계좌은행
			invitationAccountGroom :'', // 신랑계좌
			invitationAccountGroomMasterName :'', // 신랑계좌예금주
			invitationAccountGroomKakao :'', // 신랑카카오페이송금링크
			invitationAccountGroomFatherBank :'', // 신랑아버지계좌은행
			invitationAccountGroomFather :'', // 신랑아버지계좌
			invitationAccountGroomFatherMasterName :'', // 신랑아버지계좌예금주
			invitationAccountGroomFatherKakao :'', // 신랑아버지카카오페이송금링크
			invitationAccountGroomMotherBank :'', // 신랑어머니계좌은행
			invitationAccountGroomMother :'', // 신랑어머니계좌
			invitationAccountGroomMotherMasterName :'', // 신랑어머니계좌예금주
			invitationAccountGroomMotherKakao :'', // 신랑어머니카카오페이송금링크
			invitationAccountGroomOpenYn : 'N', // 신랑계좌펼처두기유무
			invitationAccountBrideGroupName :'신부측 계좌번호', // 신부계좌번호그룹명
			invitationAccountBrideBank :'', // 신부계좌은행
			invitationAccountBride :'', // 신부계좌
			invitationAccountBrideMasterName :'', // 신부계좌예금주
			invitationAccountBrideKakao :'', // 신부카카오페이송금링크
			invitationAccountBrideFatherBank :'', // 신부아버지계좌은행
			invitationAccountBrideFather :'', // 신부아버지계좌
			invitationAccountBrideFatherMasterName :'', // 신부아버지계좌예금주
			invitationAccountBrideFatherKakao :'', // 신부아버지카카오페이송금링크
			invitationAccountBrideMotherBank :'', // 신부어머니계좌은행
			invitationAccountBrideMother :'', // 신부어머니계좌
			invitationAccountBrideMotherMasterName :'', // 신부어머니계좌예금주
			invitationAccountBrideMotherKakao :'', // 신부어머니카카오페이송금링크
			invitationAccountBrideOpenYn : 'N', // 신부계좌펼처두기유무
			invitationYoutubeYn : 'Y', // 유튜브사용유무
			invitationYoutubeTitle :'', // 유튜브제목
			invitationYoutube :'', // 유튜브주소
			invitationGuestBookYn : 'Y', // 방명록사용유무
			invitationGuestBookTitle :'방명록', // 방명록제목
			invitationGuestBookPassword :'', // 방명록비밀번호
			invitationGuestBookDateVisibleYn : 'Y', // 방명록작업일표시유무
			invitationEndYn : 'Y', // 마무리글귀사용유무
			invitationEndText :'장담하건대, 세상이 다 겨울이어도 \n우리 사랑은 늘 봄처럼 따뜻하고 \n간혹, 여름처럼 뜨거울 겁니다\n\n이수동, 사랑가', // 마무리글귀
			invitationAttendYn : 'Y', // 참석의사사용유무
			invitationAttendTitle :'참석 의사 전달', // 참석의사제목
			invitationAttendContent :'축하의 마음으로 참석해주시는 \n모든 분들을 귀하게 모실 수 있도록 \n참석 의사를 전달해 주시면 감사하겠습니다.', // 참석의사내용
			invitationAttendManCountYn : 'Y', // 참석인원체크유무
			invitationAttendNumberYn : 'Y', // 참석인원전화번호유무
			invitationAttendEatYn : 'Y', // 참석인원식사여부
			invitationAttendMessageYn : 'Y', // 참석인원전달메세지유무
			invitationAttendPopupYn : 'Y', // 참석인원팝업유무
			invitationKakaoShareThumbnailYn : 'Y', // 카카오공유썸네일사용유무
			invitationKakaoShareThumbnailTitle :'', // 카카오공유썸네일제목
			invitationKakaoShareThumbnailContent :'', // 카카오공유썸네일내용
			invitationUrlShareThumbnailYn : 'Y', // URL공유썸네일사용유무
			invitationUrlShareThumbnailTitle :'', // URL공유썸네일제목
			invitationUrlShareThumbnailContent :'', // URL공유썸네일내용
			invitationLayoutSortYn : 'N', // 레이아웃순서변경사용유무
			invitationLayoutSortJson :'[{"value":"GreetingWord","title":"인사말"},{"value":"time","title":"달력"},{"value":"addrestransportation","title":"교통수단"},{"value":"gallery","title":"갤러리"},{"value":"youtube","title":"동영상"},{"value":"attend","title":"참석의사"},{"value":"account","title":"계좌번호"},{"value":"guestbook","title":"방명록"},{"value":"end","title":"마무리글귀"}]', // 레이아웃순서json
		
		
			invitationMainImageFile:null,
			invitationEndImageFile:null,
			invitationKakaoShareThumbnailFile:null,
			invitationUrlShareThumbnailFile:null,
			invitationGalleryImagesFile:[],
			invitationGalleryBeforeImagesFile:[],
			
		}
}

export default {
  name: 'Write',
  components: {WritePreview,WriteThema,WriteGroomBrideInfo,WriteMain,WriteGreetingWord,WriteTime,WriteAddress,WriteTransportation,
  WriteGallery,WriteContact,WriteAccount,WriteYoutube,WriteGuestBook,WriteEnd,WriteAttend,WriteKakaoShareThumbnail,WriteUrlShareThumbnail,WriteLayoutSort},
  computed:{
      ...mapGetters(['getPreviewFlag','getFullScreenFlag']),
  },
  data(){
    return {
		invitation:defaultInvitation(),
		invitationId:this.$route.query.invitationId ? this.$route.query.invitationId : null,
		email:this.$route.query.email ? this.$route.query.email : null,
		changeKey:1
    }
  },
  
  created(){
    
  },
  mounted(){
		const context =this
		debugger
		if(this.invitationId!==null){
			const parameter ={
				invitationId: this.invitationId,
				email: this.email
			}
			
			this.$store.dispatch("REQUEST_INVITATION_SELECT",parameter).then((data) => {
					if (data.data !== undefined && data.data!==null) {
						context.invitation =data.data
						setTimeout(function() {
							
						EventBus.$emit("invitationInit", context.invitation);
						 context.themeFixed() 
						 }, 500);
						
					} 
			})

		}else{
        	EventBus.$emit("invitationInit", this.invitation);
		}
		EventBus.$on("clickInvitationSave", (param) => {
			context.save()
		});
	this.clickCloseBtn()
  },
  beforeDestroy(){
	this.clickCloseBtn()
	EventBus.$off('clickInvitationSave')
	this.writeDestroy()
  },
  methods: {
	clickCloseBtn(){

      this.$store.commit("PREVIEW_CLICK_BTN",false)
	},
	changeData(data){
		this.invitation = data
		this.changeKey += 1
        EventBus.$emit("invitationInit", this.invitation);
	},
	save(){
      let formData = new FormData();

      for (const key in this.invitation) {
        if (this.invitation[key] !== null) {
			if(this.invitation[key]!==null && this.invitation[key]!==undefined){
				if (typeof this.invitation[key] === 'object' && !Array.isArray(this.invitation[key])){
					for (const key2 in this.invitation[key]) {
						if(this.invitation[key][key2]!==null)
          					formData.append(`${key}.${key2}`, this.invitation[key][key2])
					}
				} else if(typeof this.invitation[key] === 'object' && Array.isArray(this.invitation[key])){

					for(var i=0;i<this.invitation[key].length;i++){
						if (typeof this.invitation[key][i] === 'object' && !Array.isArray(this.invitation[key][i])){
							for (const key2 in this.invitation[key][i]) {
								if(this.invitation[key][i][key2]!==null)
									formData.append(`${key}[${i}].${key2}`, this.invitation[key][i][key2])
							}
						}
					}
				}else{
					if(this.invitation[key]!==null)
          				formData.append(`${key}`, this.invitation[key])
				}
			}
        }
      }

	  if(this.invitation.invitationId === null){
     	this.$store.dispatch("REQUEST_INSERT_INVITATION",formData)
	  }else{
     	this.$store.dispatch("REQUEST_UPDATE_INVITATION",formData)
	  }

	  

	}

  },

}
</script>
