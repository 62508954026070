<template>
	<div class="acco-list">
		<a href="javascript:" class="acco-tit" value="address"><span>예식장소</span></a>
		<div class="acco-con" >
			<section>
				<div class="acco-sec">
					<p class="tit">제목</p>
					<div class="con">
						<div class="form-input w340">
							<CustomFocusOutInput v-model="subInvitation.invitationAddressTitle" :placeholder="'제목을 입력하세요.'" @input="changeInput"/>
						</div>
					</div>
				</div>
				<div class="acco-sec">
					<p class="tit">예식장명</p>
					<div class="con">
						<div class="form-input w340">
							<CustomFocusOutInput v-model="subInvitation.invitationAddressBuildingName" :placeholder="'예식장 이름 입력'" @input="changeInput"/>
						</div>
					</div>
				</div>
				<div class="acco-sec">
					<p class="tit">층과 홀</p>
					<div class="con">
						<div class="form-input w340">
							<CustomFocusOutInput v-model="subInvitation.invitationAddressBuildingFloor" :placeholder="'층과 웨딩홀 이름 입력'" @input="changeInput"/>
						</div>
					</div>
				</div>
				<div class="acco-sec">
					<p class="tit">연락처</p>
					<div class="con">
						<div class="form-input w340">
							<CustomFocusOutInput v-model="subInvitation.invitationAddressBuildingNumber" :placeholder="'예식장 연락처 ex.02-000-0000'" @input="changeInput"/>
						</div>
					</div>
				</div>
				<div class="acco-sec align-top">
					<p class="tit">주소</p>
					<div class="con">
						<div class="form-address">
							<div class="address-div">
								<div class="form-input">
									<input type="text" placeholder="" readonly :value="subInvitation.invitationAddress" @click="clickPost">
								</div>
								<a href="javascript:" @click="clickPost" class="black-btn"><span>검색</span></a>

							</div>

							
							<div class="map-area">
								<p class="txt">도로명, 건물명 등을 검색하여 주소를 등록해 주세요.</p>
								<!-- 지도가 들어가는 영역 -->
								
    							<KakaoMap :invitation="subInvitation" @changeAddressData="changeAddressData"/>
								<div class="map-div"></div>
								<!-- // 지도가 들어가는 영역 -->
							</div>

						</div>
					</div>
				</div>
				<div class="acco-sec">
					<p class="tit">지도표시</p>
					<div class="con">
						<div class="form-checkbox no-txt">
							<input type="checkbox" id="Chk12" name="" @change="changeInvitationAddressMapYn" :checked="subInvitation.invitationAddressMapYn==='Y'">
							<label for="Chk12"></label>
						</div>
					</div>
				</div>
				<div class="acco-sec">
					<p class="tit">지도 잠금</p>
					<div class="con">
						<div class="form-checkbox">
							<input type="checkbox" id="Chk13" name="" @change="changeInvitationAddressExtendsYn" :checked="subInvitation.invitationAddressExtendsYn==='Y'">
							<label for="Chk13">지도 터치 및 줌 조정이 안되도록 막습니다.</label>
						</div>
					</div>
				</div>
				<div class="acco-sec">
					<p class="tit">네비게이션</p>
					<div class="con">
						<div class="form-checkbox">
							<input type="checkbox" id="Chk14" name="" @change="changeInvitationAddressNavigationBtnYn" :checked="subInvitation.invitationAddressNavigationBtnYn==='Y'">
							<label for="Chk14">내비 앱 바로가기 버튼 표시 (카카오내비, 티맵, 네이버)</label>
						</div>
					</div>
				</div>
			</section>
		</div>
	</div>
</template>


<script>
import EventBus from "@/utils/CommonEventBus";
import CustomFocusOutInput from "@/components/common/CustomFocusOutInput.vue";
import KakaoMap from "@/components/common/KakaoMap.vue";

export default {
  name: 'WriteAddress',
  props:[],
  components: {CustomFocusOutInput,KakaoMap},
  computed:{
  },
  data(){
    return {
		subInvitation:{}
    }
  },
  
  created(){
    
  },
  mounted(){
	const context = this
    EventBus.$on("invitationInit", (invitationInit) => {
		context.subInvitation =  invitationInit;
	});
  },
  beforeDestroy(){
    EventBus.$off('invitationInit')
  },
  methods: {
	changeInput(){
		this.$emit('changeData',this.subInvitation)
	},
	clickPost(){
		const param = {
			flag: 'postPop'
		}
		EventBus.$emit('layerPopup',param)
	},
	changeAddressData(data){
		this.subInvitation.invitationAddress = data.invitationAddress
        this.subInvitation.invitationAddressX = data.invitationAddressX
        this.subInvitation.invitationAddressY = data.invitationAddressY
		this.$emit('changeData',this.subInvitation)
	},
	changeInvitationAddressMapYn(e){
		if(e.target.checked){
			this.subInvitation.invitationAddressMapYn = 'Y'
		}else{

			this.subInvitation.invitationAddressMapYn = 'N'
		}
		this.$emit('changeData',this.subInvitation)

	},
	changeInvitationAddressExtendsYn(e){
		if(e.target.checked){
			this.subInvitation.invitationAddressExtendsYn = 'Y'
		}else{

			this.subInvitation.invitationAddressExtendsYn = 'N'
		}
		this.$emit('changeData',this.subInvitation)
		EventBus.$emit('mapExtendsYn',this.subInvitation.invitationAddressExtendsYn !== 'Y');

	},
	changeInvitationAddressNavigationBtnYn(e){
		if(e.target.checked){
			this.subInvitation.invitationAddressNavigationBtnYn = 'Y'
		}else{

			this.subInvitation.invitationAddressNavigationBtnYn = 'N'
		}
		this.$emit('changeData',this.subInvitation)

	}
  },

}
</script>
