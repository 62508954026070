<template>
	<div class="acco-list">
		<a href="javascript:" class="acco-tit">
			<div class="form-checkbox">
				<input type="checkbox" id="Chk18" name="" @change="changeInvitationAccountYn" :checked="subInvitation.invitationAccountYn==='Y'">
				<label for="Chk18">계좌번호</label>
			</div>
		</a>
		<div class="acco-con">
			<section>
				<div class="acco-sec align-top">
					<p class="tit">제목 및 내용</p>
					<div class="con">
						<div class="form-input-ta">
							<div class="form-input w100p">							
								<CustomFocusOutInput v-model="subInvitation.invitationAccountTitle" :placeholder="'제목 입력'" @input="changeInput"/>

							</div>
							<div class="form-textarea">
								<CustomFocusOutTextarea v-model="subInvitation.invitationAccountContent" :placeholder="'내용입력'" @input="changeInput"/>
								
							</div>
						</div>
					</div>
				</div>
			</section>
			<section>
				<div class="acco-sec">
					<p class="tit">그룹명</p>
					<div class="con">
						<div class="form-input w100p">
							<CustomFocusOutInput v-model="subInvitation.invitationAccountGroomGroupName" :placeholder="'그룹명 입력'" @input="changeInput"/>
						</div>
					</div>
				</div>
				<div class="acco-sec">
					<p class="tit">펼쳐두기</p>
					<div class="con">
						<div class="form-checkbox no-txt">
							<input type="checkbox" id="Chk19" name="" @change="changeInvitationAccountGroomOpenYn" :checked="subInvitation.invitationAccountGroomOpenYn==='Y'">
							<label for="Chk19"></label>
						</div>
					</div>
				</div>
				<div class="acco-sec">
					<p class="tit">신랑</p>
					<div class="con">
						<div class="form-flex-box">
							<div class="form-input w100p">
								<CustomFocusOutInput v-model="subInvitation.invitationAccountGroomBank" :placeholder="'은행'" @input="changeInput"/>
							</div>
							<div class="form-input w100p">
								<CustomFocusOutInput v-model="subInvitation.invitationAccountGroom" :placeholder="'계좌번호'" @input="changeInput"/>
							</div>
							<div class="form-input w100p">
								<CustomFocusOutInput v-model="subInvitation.invitationAccountGroomMasterName" :placeholder="'예금주'" @input="changeInput"/>
						</div>
						</div>
						<div class="form-input w100p kakao-account">
								<CustomFocusOutInput v-model="subInvitation.invitationAccountGroomKakao" :placeholder="'카카오페이 송금 링크'" @input="changeInput"/>
						</div>
					</div>
				</div>
				<div class="acco-sec">
					<p class="tit">신랑 아버지</p>
					<div class="con">
						<div class="form-flex-box">
							<div class="form-input w100p">								
								<CustomFocusOutInput v-model="subInvitation.invitationAccountGroomFatherBank" :placeholder="'은행'" @input="changeInput"/>
							</div>
							<div class="form-input w100p">								
								<CustomFocusOutInput v-model="subInvitation.invitationAccountGroomFather" :placeholder="'계좌번호'" @input="changeInput"/>
							</div>
							<div class="form-input w100p">								
								<CustomFocusOutInput v-model="subInvitation.invitationAccountGroomFatherMasterName" :placeholder="'예금주'" @input="changeInput"/>
						</div>
						</div>
						<div class="form-input w100p kakao-account">
								<CustomFocusOutInput v-model="subInvitation.invitationAccountGroomFatherKakao" :placeholder="'카카오페이 송금 링크'" @input="changeInput"/>
						</div>
					</div>
				</div>
				<div class="acco-sec">
					<p class="tit">신랑 어머니</p>
					<div class="con">
						<div class="form-flex-box">
							<div class="form-input w100p">
								<CustomFocusOutInput v-model="subInvitation.invitationAccountGroomMotherBank" :placeholder="'은행'" @input="changeInput"/>
							</div>
							<div class="form-input w100p">
								<CustomFocusOutInput v-model="subInvitation.invitationAccountGroomMother" :placeholder="'계좌번호'" @input="changeInput"/>
							</div>
							<div class="form-input w100p">
								<CustomFocusOutInput v-model="subInvitation.invitationAccountGroomMotherMasterName" :placeholder="'예금주'" @input="changeInput"/>
						</div>
						</div>
						<div class="form-input w100p kakao-account">
								<CustomFocusOutInput v-model="subInvitation.invitationAccountGroomMotherKakao" :placeholder="'카카오페이 송금 링크'" @input="changeInput"/>
						</div>
					</div>
				</div>
			</section>
			<section>
				<div class="acco-sec">
					<p class="tit">그룹명</p>
					<div class="con">
						<div class="form-input w100p">								
							<CustomFocusOutInput v-model="subInvitation.invitationAccountBrideGroupName" :placeholder="'그룹명 입력'" @input="changeInput"/>
						</div>
					</div>
				</div>
				<div class="acco-sec">
					<p class="tit">펼쳐두기</p>
					<div class="con">
						<div class="form-checkbox no-txt">
							<input type="checkbox" id="Chk20" name="" @change="changeInvitationAccountBrideOpenYn" :checked="subInvitation.invitationAccountBrideOpenYn==='Y'">
							<label for="Chk20"></label>
						</div>
					</div>
				</div>
				<div class="acco-sec">
					<p class="tit">신부</p>
					<div class="con">
						<div class="form-flex-box">
							<div class="form-input w100p">								
								<CustomFocusOutInput v-model="subInvitation.invitationAccountBrideBank" :placeholder="'은행'" @input="changeInput"/>

							</div>
							<div class="form-input w100p">								
								<CustomFocusOutInput v-model="subInvitation.invitationAccountBride" :placeholder="'계좌번호'" @input="changeInput"/>

							</div>
							<div class="form-input w100p">								
								<CustomFocusOutInput v-model="subInvitation.invitationAccountBrideMasterName" :placeholder="'예금주'" @input="changeInput"/>

						</div>
						</div>
						<div class="form-input w100p kakao-account">
								<CustomFocusOutInput v-model="subInvitation.invitationAccountBrideKakao" :placeholder="'카카오페이 송금 링크'" @input="changeInput"/>
						</div>
					</div>
				</div>
				<div class="acco-sec">
					<p class="tit">신부 아버지</p>
					<div class="con">
						<div class="form-flex-box">
							<div class="form-input w100p">
								<CustomFocusOutInput v-model="subInvitation.invitationAccountBrideFatherBank" :placeholder="'은행'" @input="changeInput"/>
							</div>
							<div class="form-input w100p">
								<CustomFocusOutInput v-model="subInvitation.invitationAccountBrideFather" :placeholder="'계좌번호'" @input="changeInput"/>
							</div>
							<div class="form-input w100p">
								<CustomFocusOutInput v-model="subInvitation.invitationAccountBrideFatherMasterName" :placeholder="'예금주'" @input="changeInput"/>
						</div>
						</div>
						<div class="form-input w100p kakao-account">
								<CustomFocusOutInput v-model="subInvitation.invitationAccountBrideFatherKakao" :placeholder="'카카오페이 송금 링크'" @input="changeInput"/>
						</div>
					</div>
				</div>
				<div class="acco-sec">
					<p class="tit">신부 어머니</p>
					<div class="con">
						<div class="form-flex-box">
							<div class="form-input w100p">
								<CustomFocusOutInput v-model="subInvitation.invitationAccountBrideMotherBank" :placeholder="'은행'" @input="changeInput"/>
							</div>
							<div class="form-input w100p">
								<CustomFocusOutInput v-model="subInvitation.invitationAccountBrideMother" :placeholder="'계좌번호'" @input="changeInput"/>
							</div>
							<div class="form-input w100p">
								<CustomFocusOutInput v-model="subInvitation.invitationAccountBrideMotherMasterName" :placeholder="'예금주'" @input="changeInput"/>
						</div>
						</div>
						<div class="form-input w100p kakao-account">
								<CustomFocusOutInput v-model="subInvitation.invitationAccountBrideMotherKakao" :placeholder="'카카오페이 송금 링크'" @input="changeInput"/>
						</div>
					</div>
				</div>
			</section>
			<div class="info-box">
				<p>
					<span class="dot-txt"><a style=" text-decoration: underline;" @click="clickKakaoInfoPopup">카톡 송금링크 복사하는 방법</a></span>
					<span class="dot-txt">계좌번호가 입력된 항목만 표시됩니다.</span>
				</p>
			</div>
		</div>
	</div>
</template>


<script>
import EventBus from "@/utils/CommonEventBus";
import CustomFocusOutInput from "@/components/common/CustomFocusOutInput.vue";
import CustomFocusOutTextarea from "@/components/common/CustomFocusOutTextarea.vue";
export default {
  name: 'WriteAccount',
  components: {CustomFocusOutInput,CustomFocusOutTextarea},
  computed:{
  },
  data(){
    return {
		subInvitation:{}
    }
  },
  
  created(){
    
  },
  mounted(){
	const context = this
    EventBus.$on("invitationInit", (invitationInit) => {
		context.subInvitation =  invitationInit;
	});
  },
  beforeDestroy(){
    EventBus.$off('invitationInit')
  },
  methods: {
	changeInput(){
		this.$emit('changeData',this.subInvitation)
	},
	changeInvitationAccountYn(e){
		if(e.target.checked){
			this.subInvitation.invitationAccountYn = 'Y'
		}else{

			this.subInvitation.invitationAccountYn = 'N'
		}
		this.$emit('changeData',this.subInvitation)

	},
	changeInvitationAccountGroomOpenYn(e){
		if(e.target.checked){
			this.subInvitation.invitationAccountGroomOpenYn = 'Y'
		}else{

			this.subInvitation.invitationAccountGroomOpenYn = 'N'
		}
		this.$emit('changeData',this.subInvitation)

	},
	changeInvitationAccountBrideOpenYn(e){
		if(e.target.checked){
			this.subInvitation.invitationAccountBrideOpenYn = 'Y'
		}else{

			this.subInvitation.invitationAccountBrideOpenYn = 'N'
		}
		this.$emit('changeData',this.subInvitation)

	},
	clickKakaoInfoPopup(){
		const param = {
			flag: 'kakaoTransInfoPop',
		}
		EventBus.$emit('layerPopup',param)
	}

  },

}
</script>
