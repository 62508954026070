<template>
	<div class="scroll-area">
		<div :class="['wedding-wrap', invitation.invitationThemeColor, invitation.invitationThemeFont, invitation.invitationThemeFontSize]">
			<!--
				배경 : gray, pink, yellow, green
				폰트 : jejumyeongjo, nanumsquareneo, kimjungchulmyungjo, bookkmyungjo
				폰트 크기 : size1 - 크게, size2 - 더 크게
			// -->
			<div class="inner">
				<WritePreviewMain :invitation="invitation"/>
				<template v-for="(sort,index) in sortList">
					<WritePreviewGreetingWord v-if="sort.value==='GreetingWord'" :key="index + 'a'" :invitation="invitation"/> 
					<WritePreviewTime v-if="sort.value==='time'" :key="index+ 'b'" :invitation="invitation"/> 
					<WritePreviewAddress v-if="sort.value==='addrestransportation'" :key="index+ 'c'" :invitation="invitation"/> 
					<WritePreviewGallery v-if="sort.value==='gallery'" :key="index + changeKey" :invitation="invitation" /> 
					<WritePreviewYoutube v-if="sort.value==='youtube'" :key="index+ 'd'" :invitation="invitation"/> 
					<WritePreviewAttend v-if="sort.value==='attend'" :key="index+ 'e'" :invitation="invitation"/> 
					<WritePreviewAccount v-if="sort.value==='account'" :key="index+ 'f'" :invitation="invitation"/> 
					<WritePreviewGuestBook v-if="sort.value==='guestbook'" :key="index+ 'g'" :invitation="invitation"/> 
					<WritePreviewEnd v-if="sort.value==='end'" :key="index+ 'h'" :invitation="invitation"/> 
				</template>
				<footer>
					<WritePreviewKakaoShareThumbnail :invitation="invitation"/>
					<WritePreviewUrlShareThumbnail :invitation="invitation"/>
					<p class="copyright">Copyright&copy;2024. <span>VIVIDMARE</span> All rightsreserved.</p>
				</footer>
			</div>
		</div>
	</div>
</template>


<script>
import WritePreviewMain from "@/components/write/preview/components/WritePreviewMain.vue";
import WritePreviewGreetingWord from "@/components/write/preview/components/WritePreviewGreetingWord.vue";
import WritePreviewTime from "@/components/write/preview/components/WritePreviewTime.vue";

import WritePreviewAddress from "@/components/write/preview/components/WritePreviewAddress.vue";

import WritePreviewGallery from "@/components/write/preview/components/WritePreviewGallery.vue";
import WritePreviewYoutube from "@/components/write/preview/components/WritePreviewYoutube.vue";
import WritePreviewAttend from "@/components/write/preview/components/WritePreviewAttend.vue";
import WritePreviewAccount from "@/components/write/preview/components/WritePreviewAccount.vue";
import WritePreviewGuestBook from "@/components/write/preview/components/WritePreviewGuestBook.vue";
import WritePreviewEnd from "@/components/write/preview/components/WritePreviewEnd.vue";

import WritePreviewKakaoShareThumbnail from "@/components/write/preview/components/WritePreviewKakaoShareThumbnail.vue";
import WritePreviewUrlShareThumbnail from "@/components/write/preview/components/WritePreviewUrlShareThumbnail.vue";


export default {
  name: 'WritePreview',
  props:['invitation','changeKey'],
  components: {WritePreviewMain,WritePreviewGreetingWord,WritePreviewTime,WritePreviewAddress,WritePreviewGallery,WritePreviewYoutube,WritePreviewAttend,WritePreviewAccount,WritePreviewGuestBook,
  WritePreviewEnd,WritePreviewKakaoShareThumbnail,WritePreviewUrlShareThumbnail},
  computed:{
	sortList(){
		return JSON.parse(this.invitation.invitationLayoutSortJson);
	}
  },
  data(){
    return {
    }
  },
  
  created(){
    
  },
  mounted(){
  },
  methods: {

  },

}
</script>
