<template>
    <div>
        <a  href="javascript:" @click="openColor" :class="isEmpty(fixedColor)?'color':'color2'" :style="'background-color:'+fixedColor"><span>{{isEmpty(fixedColor)?'기본컬러':''}}</span></a>
        <div v-if="chromeColorFlag" style="position: absolute; z-index: 100000;">
            <Chrome  :value="colors" @input="updateValue" ></Chrome>
			<div   style="flex-wrap: wrap;display: flex;">
				<a href="javascript:" @click="clickColorSelect" class="black-btn" style="width:50%"><span>선택</span></a>
				<a href="javascript:" @click="clickColorInit" class="black-btn" style="width:50%"><span>기본컬러</span></a>
			</div>
        </div>
    </div>
</template>
<script>
import EventBus from "@/utils/CommonEventBus";
import { Photoshop,Chrome,Swatches } from 'vue-color'
export default {
    name: 'CustomColorSelect',
    props:['type'],
    components: {Photoshop,Chrome,Swatches },
    data() {
        return{
            colors: '#aaaaaa',
            selectedColor:'',
            fixedColor:'',
            chromeColorFlag:false,

            subInvitation:{},
            invitationMainTextAndColorJson:{}
        }
    },
    mounted(){
        const context = this
        EventBus.$on("invitationInit", (invitationInit) => {
            context.chromeColorFlag = false
            context.subInvitation =  invitationInit;
		    context.invitationMainTextAndColorJson = JSON.parse(context.subInvitation.invitationMainTextAndColorJson)

            context.fixedColor = context.invitationMainTextAndColorJson[context.type]
        });
        
    },
    beforeDestroy(){
        EventBus.$off('invitationInit')
    },
	methods: {
        updateValue(e){
            if(e!==null && e!==undefined){

                this.selectedColor = e.hex
            }
        },
        openColor(){
            this.colors =  this.fixedColor
            this.chromeColorFlag = true
        },
        clickColorSelect(){
            this.chromeColorFlag = false
            this.fixedColor = this.selectedColor

            this.changeInput()
        },
        clickColorInit(){
            this.chromeColorFlag = false
            this.selectedColor = ''
            this.fixedColor = ''

            this.changeInput()

        },
        changeInput(){
            this.invitationMainTextAndColorJson[this.type] = this.fixedColor
            
            this.subInvitation.invitationMainTextAndColorJson = JSON.stringify(this.invitationMainTextAndColorJson)
            this.$emit('changeData',this.subInvitation)
        },
    }
}
</script>