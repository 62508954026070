<template>
  <div class="acco-list">
		<a href="javascript:" class="acco-tit">
			<div class="form-checkbox">
				<input type="checkbox" id="Chk33" name="" @change="changeInvitationLayoutSortYn" :checked="subInvitation.invitationLayoutSortYn==='Y'">
				<label for="Chk33">레이아웃 순서변경</label>
			</div>
		</a>
		<div class="acco-con">
			<section>
				<div class="acco-sec align-top">
					<ul id="sortable">
						<li class="ui-state-default"  v-for="(invitationLayoutSort,index) in invitationLayoutSortJson" :key="index" >{{invitationLayoutSort.title}}</li>
					</ul>
				</div>
			</section>
			<div class="info-box">
				<p>항목을 드래그하여 순서를 변경할 수 있습니다.</p>
			</div>
		</div>
	</div>
</template>


<script>
import EventBus from "@/utils/CommonEventBus";
export default {
  name: 'WriteLayoutSort',
  components: {},
  computed:{
  },
  data(){
    return {
		subInvitation:{},
		invitationLayoutSortJson :[], // 레이아웃순서json
		
		items:[]

    }
  },
  
  created(){
    
  },
  mounted(){
	const context = this
    EventBus.$on("invitationInit", (invitationInit) => {
		context.subInvitation =  invitationInit;
		if(context.invitationLayoutSortJson.length===0){
			context.invitationLayoutSortJson = JSON.parse(context.subInvitation.invitationLayoutSortJson)
		}
		context.items = JSON.parse(context.subInvitation.invitationLayoutSortJson)
	});
    EventBus.$on("sortableOldNew", (parameter) => {
		var items = JSON.parse(JSON.stringify(this.items));


		const items2 = items.splice(parameter.oldIndex,1);
		items.splice(parameter.newIndex, 0, items2[0]);

		this.items = items
		this.subInvitation.invitationLayoutSortJson =JSON.stringify(items)
		
		this.$emit('changeData',this.subInvitation)



	});
	
  },
  beforeDestroy(){
    EventBus.$off('invitationInit')
    EventBus.$off('sortableOldNew')
  },
  methods: {
	

	changeInvitationLayoutSortYn(e){
		if(e.target.checked){
			this.subInvitation.invitationLayoutSortYn = 'Y'
		}else{

			this.subInvitation.invitationLayoutSortYn = 'N'
		}

		this.subInvitation.invitationLayoutSortJson = '[{"value":"GreetingWord","title":"인사말"},{"value":"time","title":"달력"},{"value":"addrestransportation","title":"예식장소"},{"value":"gallery","title":"갤러리"},{"value":"youtube","title":"동영상"},{"value":"attend","title":"참석의사"},{"value":"account","title":"계좌번호"},{"value":"guestbook","title":"방명록"},{"value":"end","title":"마무리글귀"}]'
		this.$emit('changeData',this.subInvitation)

	},
  },

}
</script>
