<template>
	<div  :class="['img-view', 'type3', 'complex',Number(subInvitation.invitationGalleryTypeArray) === 2?'array2':'array3']" :key="changeKey">

		<!--
			type1, type2 : view-list 4개 생성 부탁드립니다.
			type3 : array2일 경우 view-list 2개, array3일 경우 view-list 3개 생성 부탁드립니다.
			type3 complex (복합) : file-img-area에 랜덤으로 long, short class 추가 부탁드립니다.
			type3 long (직사각형) : file-img-area에 전체 long class 추가 부탁드립니다.
		-->
		<template v-if="subInvitation.invitationGalleryImagesFile!==undefined && subInvitation.invitationGalleryImagesFile!==null && subInvitation.invitationGalleryImagesFile.length>0" >
			
			<div  v-for="(invitationGalleryImageFile,index) in subInvitation.invitationGalleryImagesFile" :key="index " :class="['file-img-area',  pictureRation(invitationGalleryImageFile)]" >
				<img :src="eixstIdMakeDisplayUrl(invitationGalleryImageFile)"  alt="">
				<button type="button" class="delete-btn" @click="deleteInvitationGalleryImageFile(index)" @touchend="deleteInvitationGalleryImageFile(index)"></button>
				<button type="button" class="edit-btn" @click="modifyInvitationGalleryImageFile(index)" @touchend="modifyInvitationGalleryImageFile(index)"><span>썸네일 편집</span></button>
			</div>
		</template>
		
	</div>
</template>


<script>
import EventBus from "@/utils/CommonEventBus";
export default {
  props:['changeKey'],
  name: 'WriteGalleryGride',
  components: {},
  computed:{
  },
  data(){
    return {
		subInvitation:{},
    }
  },
  
  created(){
    
  },
  mounted(){
	const context = this
    EventBus.$on("invitationInit", (invitationInit) => {
		context.subInvitation =  invitationInit;
	});
  },
  beforeDestroy(){
    EventBus.$off('invitationInit')
  },
  methods: {
	pictureRation(invitationGalleryImageFile){
		if(this.subInvitation.invitationGalleryTypeRatio !== 'square')
			if(this.subInvitation.invitationGalleryTypeRatio === 'complex'){
				return invitationGalleryImageFile.fileValue1
			}else
				return this.subInvitation.invitationGalleryTypeRatio
		else
			return ''
	},
	
	changeInput(){
		this.$emit('changeData',this.subInvitation)
	},
	deleteInvitationGalleryImageFile(index){
		this.subInvitation.invitationGalleryImagesFile.splice(index,1)
		this.subInvitation.invitationGalleryBeforeImagesFile.splice(index,1)
		this.changeInput()
	},
	modifyInvitationGalleryImageFile(index){
		this.$emit('modifyFile',index);
	}
  },

}
</script>
