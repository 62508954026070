<template>
  <div class="acco-list">
		<a href="javascript:" class="acco-tit">
			<div class="form-checkbox">
				<input type="checkbox" id="Chk21" name=""  @change="changeInvitationYoutubeYn" :checked="subInvitation.invitationYoutubeYn==='Y'">
				<label for="Chk21">동영상</label>
			</div>
		</a>
		<div class="acco-con">
			<section>
				<div class="acco-sec">
					<p class="tit">제목</p>
					<div class="con">
						<div class="form-input w100p">
							<CustomFocusOutInput v-model="subInvitation.invitationYoutubeTitle" :placeholder="'최대 30자'" @input="changeInput"/>

						</div>
					</div>
				</div>
				<div class="acco-sec">
					<p class="tit">유튜브 URL</p>
					<div class="con">
						<div class="form-input w100p">
							<CustomFocusOutInput v-model="subInvitation.invitationYoutube" :placeholder="'유튜브에 업로드한 영상의 URL을 입력'" @input="changeInput"/>
						</div>
					</div>
				</div>
			</section>
			<div class="info-box">
				<p>
					<span class="dot-txt">유튜브에 업로드한 영상의 URL을 입력하세요.</span>
					<span class="dot-txt">청첩장에서 재생할 수 있도록 "퍼가기 허용"을 설정하세요.</span>
				</p>
			</div>
		</div>
	</div>	
</template>


<script>
import EventBus from "@/utils/CommonEventBus";
import CustomFocusOutInput from "@/components/common/CustomFocusOutInput.vue";
export default {
  name: 'WriteYoutube',
  components: {CustomFocusOutInput},
  computed:{
  },
  data(){
    return {
		subInvitation:{}
    }
  },
  
  created(){
    
  },
  mounted(){
	const context = this
    EventBus.$on("invitationInit", (invitationInit) => {
		context.subInvitation =  invitationInit;
	});
  },
  beforeDestroy(){
    EventBus.$off('invitationInit')
  },
  methods: {
	changeInput(){
		this.$emit('changeData',this.subInvitation)
	},
	changeInvitationYoutubeYn(e){
		if(e.target.checked){
			this.subInvitation.invitationYoutubeYn = 'Y'
		}else{

			this.subInvitation.invitationYoutubeYn = 'N'
		}
		this.$emit('changeData',this.subInvitation)

	},
	youtubeInfoBlogPop(){
		window.open('http://pf.kakao.com/_xcKMaG/104520150')
	}

  },

}
</script>
