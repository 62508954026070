<template>
  <div class="acco-list">
		<a href="javascript:" class="acco-tit">
			<div class="form-checkbox">
				<input type="checkbox" id="Chk25" name="" @change="changeInvitationAttendYn" :checked="subInvitation.invitationAttendYn==='Y'">
				<label for="Chk25">참석의사 RSVP</label>
			</div>
		</a>
		<div class="acco-con">
			<section>
				<div class="acco-sec align-top">
					<p class="tit">제목 및 내용</p>
					<div class="con">
						<div class="form-input-ta">
							<div class="form-input w100p">								
								<CustomFocusOutInput v-model="subInvitation.invitationAttendTitle" :placeholder="'제목 입력'" @input="changeInput"/>

							</div>
							<div class="form-textarea">							
								<CustomFocusOutTextarea v-model="subInvitation.invitationAttendContent" :placeholder="'내용입력'" @input="changeInput"/>

							</div>
						</div>
					</div>
				</div>
				<div class="acco-sec">
					<p class="tit">항목</p>
					<div class="con">
						<div class="form-checkbox-w">
							<div class="form-checkbox">
								<input type="checkbox" id="Chk26" name=""  @change="changeInvitationAttendManCountYn" :checked="subInvitation.invitationAttendManCountYn==='Y'">
								<label for="Chk26">인원</label>
							</div>
							<div class="form-checkbox">
								<input type="checkbox" id="Chk27" name=""  @change="changeInvitationAttendNumberYn" :checked="subInvitation.invitationAttendNumberYn==='Y'">
								<label for="Chk27">연락처</label>
							</div>
							<div class="form-checkbox">
								<input type="checkbox" id="Chk28" name=""  @change="changeInvitationAttendEatYn" :checked="subInvitation.invitationAttendEatYn==='Y'">
								<label for="Chk28">식사여부</label>
							</div>
							<div class="form-checkbox">
								<input type="checkbox" id="Chk29" name=""  @change="changeInvitationAttendMessageYn" :checked="subInvitation.invitationAttendMessageYn==='Y'">
								<label for="Chk29">전달 메세지</label>
							</div>
						</div>
					</div>
				</div>
				<div class="acco-sec">
					<p class="tit">팝업</p>
					<div class="con">
						<div class="form-checkbox-w">
							<div class="form-checkbox"> 
								<input type="checkbox" id="Chk30" name=""  @change="changeInvitationAttendPopupYn" :checked="subInvitation.invitationAttendPopupYn==='Y'">
								<label for="Chk30">청첩장 접속 시 팝업으로 안내</label>
							</div>
						</div>
					</div>
				</div>
			</section>
			<div class="info-box">
				<p>참석의사 집계 결과는 <a href="javascript:">제작내역</a>에서 확인하실 수 있습니다.</p>
			</div>
		</div>
	</div>	
</template>


<script>
import EventBus from "@/utils/CommonEventBus";
import CustomFocusOutInput from "@/components/common/CustomFocusOutInput.vue";
import CustomFocusOutTextarea from "@/components/common/CustomFocusOutTextarea.vue";
export default {
  name: 'WriteAttend',
  components: {CustomFocusOutInput,CustomFocusOutTextarea},
  computed:{
  },
  data(){
    return {
		subInvitation:{}
    }
  },
  
  created(){
    
  },
  mounted(){
	const context = this
    EventBus.$on("invitationInit", (invitationInit) => {
		context.subInvitation =  invitationInit;
	});
  },
  beforeDestroy(){
    EventBus.$off('invitationInit')
  },
  methods: {
	changeInput(){
		this.$emit('changeData',this.subInvitation)
	},
	changeInvitationAttendYn(e){
		if(e.target.checked){
			this.subInvitation.invitationAttendYn = 'Y'
		}else{

			this.subInvitation.invitationAttendYn = 'N'
		}
		this.$emit('changeData',this.subInvitation)

	},
	changeInvitationAttendManCountYn(e){
		if(e.target.checked){
			this.subInvitation.invitationAttendManCountYn = 'Y'
		}else{

			this.subInvitation.invitationAttendManCountYn = 'N'
		}
		this.$emit('changeData',this.subInvitation)

	},
	changeInvitationAttendNumberYn(e){
		if(e.target.checked){
			this.subInvitation.invitationAttendNumberYn = 'Y'
		}else{

			this.subInvitation.invitationAttendNumberYn = 'N'
		}
		this.$emit('changeData',this.subInvitation)

	},
	changeInvitationAttendEatYn(e){
		if(e.target.checked){
			this.subInvitation.invitationAttendEatYn = 'Y'
		}else{

			this.subInvitation.invitationAttendEatYn = 'N'
		}
		this.$emit('changeData',this.subInvitation)

	},
	changeInvitationAttendMessageYn(e){
		if(e.target.checked){
			this.subInvitation.invitationAttendMessageYn = 'Y'
		}else{

			this.subInvitation.invitationAttendMessageYn = 'N'
		}
		this.$emit('changeData',this.subInvitation)

	},
	changeInvitationAttendPopupYn(e){
		if(e.target.checked){
			this.subInvitation.invitationAttendPopupYn = 'Y'
		}else{

			this.subInvitation.invitationAttendPopupYn = 'N'
		}
		this.$emit('changeData',this.subInvitation)

	},
  },

}
</script>
