<template>
    <input type="text" :class="pclass" :placeholder="placeholder" :value="value" @focusout="inputChange">
</template>
<script>
export default {
    name: 'CustomFocusOutInput',
    props:['pclass','placeholder','value'],
    data() {
        return{
        }
    },
	methods: {
        inputChange(event){
            this.$emit('input', event.target.value);
        }
    }
}
</script>