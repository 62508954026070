<template>
	<div class="acco-list">
		<a href="javascript:" class="acco-tit">
			<div class="form-checkbox">
				<input type="checkbox" id="Chk24" name="" @change="changeInvitationEndYn" :checked="subInvitation.invitationEndYn==='Y'">
				<label for="Chk24">마무리 사진 및 글귀</label>
			</div>
		</a>
		<div class="acco-con">
			<section>
				<div class="acco-sec align-top">
					<p class="tit">사진</p>
					<div class="con">
					<div class="img-upload-w">
						<CustomInputFile :type="'end'" @uploadFile="uploadEndFile" :cropRation="endCropRation" :existFileUrl="subInvitation.invitationMainImageFile === null?null:eixstIdMakeDisplayUrl(subInvitation.invitationEndImageFile)"/>

					</div>
					</div>
				</div>
				<div class="acco-sec align-top">
					<p class="tit">글귀</p>
					<div class="con">
						<div class="form-textarea h156">								
							<CustomFocusOutTextarea v-model="subInvitation.invitationEndText" :placeholder="'내용입력'" @input="changeInput"/>
						</div>
						<a @click="clickSamplePopup" class="sample-btn"><span>샘플 문구 사용하기</span></a>
					</div>
				</div>
			</section>
		</div>
	</div>
</template>


<script>
import EventBus from "@/utils/CommonEventBus";
import CustomFocusOutTextarea from "@/components/common/CustomFocusOutTextarea.vue";
import CustomInputFile from "@/components/common/CustomInputFile.vue";
export default {
  name: 'WriteEnd',
  components: {CustomFocusOutTextarea,CustomInputFile},
  computed:{
  },
  data(){
    return {
		subInvitation:{},
		endCropRation:{
			x:3,
			y:4
		}
    }
  },
  
  created(){
    
  },
  mounted(){
	const context = this
    EventBus.$on("invitationInit", (invitationInit) => {
		context.subInvitation =  invitationInit;
	});
    EventBus.$on("popupEndWordSave", (data) => {
		context.subInvitation.invitationEndText = data
		context.$emit('changeData',this.subInvitation)
	});
  },
  beforeDestroy(){
    EventBus.$off('invitationInit')
    EventBus.$off('popupEndWordSave')
  },
  methods: {
	changeInput(){
		this.$emit('changeData',this.subInvitation)
	},
	changeInvitationEndYn(e){
		if(e.target.checked){
			this.subInvitation.invitationEndYn = 'Y'
		}else{

			this.subInvitation.invitationEndYn = 'N'
		}
		this.$emit('changeData',this.subInvitation)

	},
	clickSamplePopup(){
		const param = {
			flag: 'writeEndWordSamplePop'
		}
		EventBus.$emit('layerPopup',param)
	},
	uploadEndFile(param){
		this.subInvitation.invitationEndImageFile = param

		
		this.changeInput()
	}
  },

}
</script>
