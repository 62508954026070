<template>
	<div class="acco-list">
		<a href="javascript:" class="acco-tit">
			<div class="form-checkbox">
				<input type="checkbox" id="Chk22" name="" @change="changeInvitationGuestBookYn" :checked="subInvitation.invitationGuestBookYn==='Y'">
				<label for="Chk22">방명록</label>
			</div>
		</a>
		<div class="acco-con">
			<section>
				<div class="acco-sec">
					<p class="tit">제목</p>
					<div class="con">
						<div class="form-input w100p">								
							<CustomFocusOutInput v-model="subInvitation.invitationGuestBookTitle" :placeholder="'제목 입력'" @input="changeInput"/>
						</div>
					</div>
				</div>
				<div class="acco-sec">
					<p class="tit">비밀번호</p>
					<div class="con">
						<div class="form-input w100p">
							<CustomFocusOutInput v-model="subInvitation.invitationGuestBookPassword" :placeholder="'비밀번호 입력'" @input="changeInput"/>
						</div>
					</div>
				</div>
				<div class="acco-sec">
					<p class="tit">작성일 표시</p>
					<div class="con">
						<div class="form-checkbox no-txt">
							<input type="checkbox" id="Chk23" name="" @change="changeInvitationGuestBookDateVisibleYn" :checked="subInvitation.invitationGuestBookDateVisibleYn==='Y'">
							<label for="Chk23"></label>
						</div>
					</div>
				</div>
			</section>
			<div class="info-box">
				<p>작성된 방명록 글을 삭제하려면 비밀번호를 설정하세요.</p>
			</div>
		</div>
	</div>
</template>


<script>
import EventBus from "@/utils/CommonEventBus";
import CustomFocusOutInput from "@/components/common/CustomFocusOutInput.vue";
export default {
  name: 'WriteGuestBook',
  components: {CustomFocusOutInput},
  computed:{
  },
  data(){
    return {
		subInvitation:{}
    }
  },
  
  created(){
    
  },
  mounted(){
	const context = this
    EventBus.$on("invitationInit", (invitationInit) => {
		context.subInvitation =  invitationInit;
	});
  },
  beforeDestroy(){
    EventBus.$off('invitationInit')
  },
  methods: {
	changeInput(){
		this.$emit('changeData',this.subInvitation)
	},
	changeInvitationGuestBookYn(e){
		if(e.target.checked){
			this.subInvitation.invitationGuestBookYn = 'Y'
		}else{

			this.subInvitation.invitationGuestBookYn = 'N'
		}
		this.$emit('changeData',this.subInvitation)
	},
	changeInvitationGuestBookDateVisibleYn(e){
		if(e.target.checked){
			this.subInvitation.invitationGuestBookDateVisibleYn = 'Y'
		}else{

			this.subInvitation.invitationGuestBookDateVisibleYn = 'N'
		}
		this.$emit('changeData',this.subInvitation)

	}

  },

}
</script>
