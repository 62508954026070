<template>
	<div class="acco-list">
		<a href="javascript:" class="acco-tit"><span>테마</span></a>
		<div class="acco-con">
			<section>
				<div class="acco-sec">
					<p class="tit">글꼴</p>
					<div class="con">
						<div class="form-flex-box">
							<div class="form-select">
								<select v-model="subInvitation.invitationThemeFont" @change="changeData">
									<option v-for="(invitationThemeFont,index) in invitationThemeFontList" :value="invitationThemeFont.value" :key="index">{{invitationThemeFont.title}}</option>
								</select>
							</div>
							<div class="form-select">
								<select v-model="subInvitation.invitationThemeFontSize" @change="changeData">
									<option v-for="(invitationThemeFontSize,index) in invitationThemeFontSizeList" :value="invitationThemeFontSize.value" :key="index">{{invitationThemeFontSize.title}}</option>
								</select>
							</div>
						</div>
					</div>
				</div>
				<div class="acco-sec">
					<p class="tit">컬러</p>
					<div class="con">
						<div class="color-chip-box">
							<a v-for="(invitationThemeColor,index) in invitationThemeColorList" :key="index" :class="['color-chip', subInvitation.invitationThemeColor === invitationThemeColor?'on':'']" @click="changeThemaColor(invitationThemeColor)" href="javascript:" ><span :class="['color', invitationThemeColor]"></span></a>
		
						</div>
					</div>
				</div>
			</section>
			<section>
				<div class="acco-sec">
					<p class="tit">확대 방지</p>
					<div class="con">
						<div class="form-checkbox">
							<input type="checkbox" id="Chk1" name="" @change="changeInvitationThemeExpansionYn" :checked="subInvitation.invitationThemeExpansionYn==='Y'" >
							<label for="Chk1">청첩장 확대 방지</label>
						</div>
					</div>
				</div>
				<div class="acco-sec">
					<p class="tit">등장 효과</p>
					<div class="con">
						<div class="form-checkbox">
							<input type="checkbox" id="Chk2" name="" @change="changeInvitationThemeAppearYn" :checked="subInvitation.invitationThemeAppearYn==='Y'">
							<label for="Chk2">스크롤시 부드럽게 나타나는 효과 적용</label>
						</div>
					</div>
				</div>
			</section>
		</div>
	</div>
</template>


<script>
import EventBus from "@/utils/CommonEventBus";
export default {
  name: 'WriteThema',
  components: {},
  computed:{
  },
  data(){
    return {
		subInvitation:{}
    }
  },
  
  created(){
    
  },
  mounted(){
	const context = this
    EventBus.$on("invitationInit", (invitationInit) => {
		context.subInvitation =  invitationInit;
	});
  },
  beforeDestroy(){
    EventBus.$off('invitationInit')
  },
  methods: {
	changeData(){
		
		this.$emit('changeData',this.subInvitation)
	},
	changeThemaColor(color){
		this.subInvitation.invitationThemeColor = color
		this.$emit('changeData',this.subInvitation)
	},
	changeInvitationThemeExpansionYn(e){
		if(e.target.checked){
			this.subInvitation.invitationThemeExpansionYn = 'Y'
		}else{

			this.subInvitation.invitationThemeExpansionYn = 'N'
		}
		this.$emit('changeData',this.subInvitation)
	},
	changeInvitationThemeAppearYn(e){
		if(e.target.checked){
			this.subInvitation.invitationThemeAppearYn = 'Y'
		}else{

			this.subInvitation.invitationThemeAppearYn = 'N'
		}
		this.$emit('changeData',this.subInvitation)
	}

  },

}
</script>
