<template>
	<div class="acco-list">
		<a href="javascript:" class="acco-tit">
			<div class="form-checkbox">
				<input type="checkbox" id="Chk31" name="" @change="changeInvitationKakaoShareThumbnailYn" :checked="subInvitation.invitationKakaoShareThumbnailYn==='Y'">
				<label for="Chk31">카카오톡 공유 썸네일</label>
			</div>
		</a>
		<div class="acco-con">
			<section>
				<div class="acco-sec align-top">
					<p class="tit">사진</p>
					<div class="con">
					<div class="img-upload-w">
						<CustomInputFile :type="'kakaoShareThubnailFile'" @uploadFile="uploadKakaoShareThubnailFile" :cropRation="kakaoShareThubnail" :existFileUrl="subInvitation.invitationMainImageFile === null?null:eixstIdMakeDisplayUrl(subInvitation.invitationKakaoShareThumbnailFile)"/>

					</div>
					</div>
				</div>
				<div class="acco-sec">
					<p class="tit">제목</p>
					<div class="con">
						<div class="form-input w100p">
							<CustomFocusOutInput v-model="subInvitation.invitationKakaoShareThumbnailTitle" :placeholder="'{신랑이름} ❤️ {신부이름}의 결혼식'" @input="changeInput"/>
						</div>
					</div>
				</div>
				<div class="acco-sec">
					<p class="tit">내용</p>
					<div class="con">
						<div class="form-input w100p">							
							<CustomFocusOutInput v-model="subInvitation.invitationKakaoShareThumbnailContent" :placeholder="'{n}월 {n}일 {n}요일 {오전 or 오후} {n}시 {n}분'" @input="changeInput"/>
						</div>
					</div>
				</div>
			</section>
			<div class="info-box">
				<p>입력된 문구로 자동설정되며 변경이 필요한 경우에 입력해 주세요.</p>
			</div>
		</div>
	</div>
</template>


<script>
import EventBus from "@/utils/CommonEventBus";
import CustomFocusOutInput from "@/components/common/CustomFocusOutInput.vue";
import CustomInputFile from "@/components/common/CustomInputFile.vue";
export default {
  name: 'WriteKakaoShareThumbnail',
  components: {CustomFocusOutInput,CustomInputFile},
  computed:{
  },
  data(){
    return {
		subInvitation:{},
		kakaoShareThubnail:{
			x:1,
			y:1
		}
    }
  },
  
  created(){
    
  },
  mounted(){
	const context = this
    EventBus.$on("invitationInit", (invitationInit) => {
		context.subInvitation =  invitationInit;
	});
  },
  beforeDestroy(){
    EventBus.$off('invitationInit')
  },
  methods: {
	changeInput(){
		this.$emit('changeData',this.subInvitation)
	},
	changeInvitationKakaoShareThumbnailYn(e){
		if(e.target.checked){
			this.subInvitation.invitationKakaoShareThumbnailYn = 'Y'
		}else{

			this.subInvitation.invitationKakaoShareThumbnailYn = 'N'
		}
		this.$emit('changeData',this.subInvitation)

	},
	uploadKakaoShareThubnailFile(param){
		this.subInvitation.invitationKakaoShareThumbnailFile = param

		
		this.changeInput()
	}

  },

}
</script>
