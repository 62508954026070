<template>
	<div class="acco-list">
		<a href="javascript:" class="acco-tit"><span>예식일시</span></a>
		<div class="acco-con">
			<section>
				<div class="acco-sec">
					<p class="tit">예식일</p>
					<div class="con">
						<div class="form-input w243">
							<input type="date" placeholder="" v-model="date" @change="changeInput">
						</div>
					</div>
				</div>
				<div class="acco-sec">
					<p class="tit">예식시간</p>
					<div class="con">
						<div class="form-flex-box">
							<div class="form-select">
								<select id="" title="" v-model="hour" @change="changeInput">
									<option value="07">오전 7시</option>
									<option value="08">오전 8시</option>
									<option value="09">오전 9시</option>
									<option value="10">오전 10시</option>
									<option value="11">오전 11시</option>
									<option value="12">오후 12시</option>
									<option value="13">오후 1시</option>
									<option value="14">오후 2시</option>
									<option value="15">오후 3시</option>
									<option value="16">오후 4시</option>
									<option value="17">오후 5시</option>
									<option value="18">오후 6시</option>
									<option value="19">오후 7시</option>
									<option value="20">오후 8시</option>
									<option value="21">오후 9시</option>
								</select>
							</div>
							<div class="form-select">
								<select id="" title="" v-model="minute" @change="changeInput">
									<option value="00">00분</option>
									<option value="05">05분</option>
									<option value="10">10분</option>
									<option value="15">15분</option>
									<option value="20">20분</option>
									<option value="25">25분</option>
									<option value="30">30분</option>
									<option value="35">35분</option>
									<option value="40">40분</option>
									<option value="45">45분</option>
									<option value="50">50분</option>
									<option value="55">55분</option>
								</select>
							</div>
						</div>
					</div>
				</div>
				<div class="acco-sec align-top">
					<p class="tit">표시</p>
					<div class="con">
						<div class="form-checkbox">
							<input type="checkbox" id="Chk10" name=""  @change="changeInvitationTimeCalendarYn" :checked="subInvitation.invitationTimeCalendarYn==='Y'">
							<label for="Chk10">캘린더</label>
						</div>
						<div class="form-checkbox">
							<input type="checkbox" id="Chk11" name=""  @change="changeInvitationTimeDdayYn" :checked="subInvitation.invitationTimeDdayYn==='Y'">
							<label for="Chk11">디데이 &amp; 카운트다운</label>
						</div>
					</div>
				</div>
			</section>
		</div>
	</div>
</template>


<script>
import EventBus from "@/utils/CommonEventBus";
export default {
  name: 'WriteTime',
  props:[],
  components: {},
  computed:{
  },
  data(){
    return {
		subInvitation:{},

		date:'2099-01-01',
		hour:'11',
		minute:'30'
    }
  },
  
  created(){
    
  },
  mounted(){
	const context = this
    EventBus.$on("invitationInit", (invitationInit) => {
		context.subInvitation =  invitationInit;
		if(!context.isEmpty(context.subInvitation.invitationTimeDatetime)){
			context.date =  context.subInvitation.invitationTimeDatetime.substring(0,10)
			context.hour =  context.subInvitation.invitationTimeDatetime.substring(11,13)
			context.minute =  context.subInvitation.invitationTimeDatetime.substring(14,16)
		}
	});
  },
  beforeDestroy(){
    EventBus.$off('invitationInit')
  },
  methods: {
	changeInput(){
		this.subInvitation.invitationTimeDatetime = this.date + ' ' + this.hour + ':'+this.minute + ":00";
		this.subInvitation.invitationUrlShareThumbnailContent = this.getNonYearKorDatetime(this.subInvitation.invitationTimeDatetime)
		this.subInvitation.invitationKakaoShareThumbnailContent = this.getNonYearKorDatetime(this.subInvitation.invitationTimeDatetime)
		this.$emit('changeData',this.subInvitation)
	},
	changeInvitationTimeCalendarYn(e){
		if(e.target.checked){
			this.subInvitation.invitationTimeCalendarYn = 'Y'
		}else{

			this.subInvitation.invitationTimeCalendarYn = 'N'
		}
		this.$emit('changeData',this.subInvitation)

	},
	changeInvitationTimeDdayYn(e){
		if(e.target.checked){
			this.subInvitation.invitationTimeDdayYn = 'Y'
		}else{

			this.subInvitation.invitationTimeDdayYn = 'N'
		}
		this.$emit('changeData',this.subInvitation)
	}

  },

}
</script>
