<template>
  <div class="acco-list">
		<a href="javascript:" class="acco-tit">
			<div class="form-checkbox">
				<input type="checkbox" id="Chk32" name="" @change="changeInvitationUrlShareThumbnailYn" :checked="subInvitation.invitationUrlShareThumbnailYn==='Y'">
				<label for="Chk32">URL 공유 썸네일</label>
			</div>
		</a>
		<div class="acco-con">
			<section>
				<div class="acco-sec align-top">
					<p class="tit">사진</p>
					<div class="con">
					<div class="img-upload-w">
						<CustomInputFile :type="'urlShareThubnailFile'" @uploadFile="uploadUrlShareThubnailFile" :cropRation="urlShareThubnailCropRation" :existFileUrl="subInvitation.invitationMainImageFile === null?null:eixstIdMakeDisplayUrl(subInvitation.invitationUrlShareThumbnailFile)"/>
					</div>
					</div>
				</div>
				<div class="acco-sec">
					<p class="tit">제목</p>
					<div class="con">
						<div class="form-input w100p">
							<CustomFocusOutInput v-model="subInvitation.invitationUrlShareThumbnailTitle" :placeholder="'{신랑이름} ❤️ {신부이름}의 결혼식'" @input="changeInput"/>
						</div>
					</div>
				</div>
				<div class="acco-sec">
					<p class="tit">내용</p>
					<div class="con">
						<div class="form-input w100p">
							<CustomFocusOutInput v-model="subInvitation.invitationUrlShareThumbnailContent" :placeholder="'{n}월 {n}일 {n}요일 {오전 or 오후} {n}시 {n}분'" @input="changeInput"/>
						</div>
					</div>
				</div>
			</section>
			<div class="info-box">
				<p>
					<span class="dot-txt">입력된 문구로 자동설정되며 변경이 필요한 경우에 입력해 주세요.</span>
					<span class="dot-txt">청첩장 URL을 공유할 땐 썸네일이 가로 비율(2:1)로 제한됩니다.</span>
					<span class="dot-txt">SMS(문자메시지)는 썸네일을 지원하지 않습니다.</span>
				</p>
			</div>
		</div>
	</div>
</template>


<script>
import EventBus from "@/utils/CommonEventBus";
import CustomFocusOutInput from "@/components/common/CustomFocusOutInput.vue";
import CustomInputFile from "@/components/common/CustomInputFile.vue";
export default {
  name: 'WriteUrlShareThumbnail',
  components: {CustomFocusOutInput,CustomInputFile},
  computed:{
  },
  data(){
    return {
		subInvitation:{},
		urlShareThubnailCropRation:{
			x:2,
			y:1
		}
    }
  },
  
  created(){
    
  },
  mounted(){
	const context = this
    EventBus.$on("invitationInit", (invitationInit) => {
		context.subInvitation =  invitationInit;
	});
  },
  beforeDestroy(){
    EventBus.$off('invitationInit')
  },
  methods: {
	changeInput(){
		this.$emit('changeData',this.subInvitation)
	},
	changeInvitationUrlShareThumbnailYn(e){
		if(e.target.checked){
			this.subInvitation.invitationUrlShareThumbnailYn = 'Y'
		}else{

			this.subInvitation.invitationUrlShareThumbnailYn = 'N'
		}
		this.$emit('changeData',this.subInvitation)

	},
	uploadUrlShareThubnailFile(param){
		this.subInvitation.invitationUrlShareThumbnailFile = param

		
		this.changeInput()
	}


  },

}
</script>
