<template>
	<div class="acco-list">
		<a href="javascript:" class="acco-tit">
			<div class="form-checkbox">
				<input type="checkbox" id="Chk16" name="" @change="changeInvitationGalleryYn" :checked="subInvitation.invitationGalleryYn==='Y'">
				<label for="Chk16">갤러리</label>
			</div>
		</a>
		<div class="acco-con">
			<div class="acco-div">
				<section>
					<div class="acco-sec align-top">
						<p class="tit">제목</p>
						<div class="con">
							<div class="form-input w100p">
								<CustomFocusOutInput v-model="subInvitation.invitationGalleryTitle" :placeholder="'제목을 입력하세요.'" @input="changeInput"/>

							</div>
						</div>
					</div>
					<div class="acco-sec-w">
						<div class="acco-sec">
							<p class="tit">갤러리 타입</p>
							<div class="con">
								<div class="border-tab-btn img-view-type">
									<button type="button" :class="[subInvitation.invitationGalleryType === 'basic'?'on':'']" @click="clickGalleryType('basic')"><span>기본 슬라이드</span></button>
									<button type="button" :class="[subInvitation.invitationGalleryType === 'thumbnail'?'on':'']" @click="clickGalleryType('thumbnail')"><span>썸네일 슬라이드</span></button>
									<button type="button" :class="[subInvitation.invitationGalleryType === 'grid'?'on':'']" @click="clickGalleryType('grid')"><span>그리드</span></button>
								</div>
							</div>
						</div>
						<template v-if="subInvitation.invitationGalleryType === 'grid'">
							<div class="acco-sec grid-type">
								<p class="tit">배열</p>
								<div class="con">
									<div class="border-tab-btn">
										<button type="button" :class="[subInvitation.invitationGalleryTypeArray === '2'?'on':'']" @click="clickGalleryTypeArray('2')"><span>2단</span></button>
										<button type="button" :class="[subInvitation.invitationGalleryTypeArray === '3'?'on':'']" @click="clickGalleryTypeArray('3')"><span>3단</span></button>
									</div>
								</div>
							</div>
							<div class="acco-sec grid-type">
								<p class="tit">사진비율</p>
								<div class="con">
									<div class="border-tab-btn">
										<button type="button" :class="[subInvitation.invitationGalleryTypeRatio === 'complex'?'on':'']" @click="clickGalleryTypeRatio('complex')"><span>복합</span></button>
										<button type="button" :class="[subInvitation.invitationGalleryTypeRatio === 'square'?'on':'']" @click="clickGalleryTypeRatio('square')"><span>정사각형</span></button>
										<button type="button" :class="[subInvitation.invitationGalleryTypeRatio === 'long'?'on':'']" @click="clickGalleryTypeRatio('long')"><span>직사각형</span></button>
									</div>
								</div>
							</div>
							<div class="acco-sec grid-type">
								<p class="tit">더보기</p>
								<div class="con">
									<div class="form-checkbox">
										<input type="checkbox" id="Chk48" name="" @change="changeInvitationGalleryComplexMoreYn" :checked="subInvitation.invitationGalleryComplexMoreYn==='Y'">
										<label for="Chk48">사진이 3줄 이상일 경우 더보기 버튼을 추가합니다.</label>
									</div>
								</div>
							</div>
						</template>
						<div class="add-img-w">
							<div class="top-area">
								<div class="left">
									<p class="tit">추가된 사진</p>
									<p class="num">{{subInvitation.invitationGalleryImagesFile!==undefined?subInvitation.invitationGalleryImagesFile.length:''}}/30</p>
								</div>
								<div class="right">
									<CustomInputGalleryFile :type="subInvitation.invitationGalleryType" :subtype="subInvitation.invitationGalleryTypeRatio" @uploadFile="uploadGalleryFile" :currentCount="subInvitation.invitationGalleryImagesFile.length" />


								</div>
							</div>
							<div class="bot-area">
								<div class="scroll-area">
									<WriteGalleryBasic  v-show="subInvitation.invitationGalleryType === 'basic'" @changeData="changeData" @modifyFile="modifyFile" :changeKey="changeKey"/>
									<WriteGalleryThubnail  v-show="subInvitation.invitationGalleryType === 'thumbnail'" @changeData="changeData" @modifyFile="modifyFile" :changeKey="changeKey"/>
									<WriteGalleryGride  v-show="subInvitation.invitationGalleryType === 'grid'" @changeData="changeData" @modifyFile="modifyFile" :changeKey="changeKey"/>
								</div>
							</div>
						</div>
					</div>
				</section>
			</div>
		</div>
	</div>
</template>


<script>
import EventBus from "@/utils/CommonEventBus";
import CustomFocusOutInput from "@/components/common/CustomFocusOutInput.vue";
import WriteGalleryBasic from "@/components/write/gallery/WriteGalleryBasic.vue";
import WriteGalleryThubnail from "@/components/write/gallery/WriteGalleryThubnail.vue";
import WriteGalleryGride from "@/components/write/gallery/WriteGalleryGride.vue";
import CustomInputGalleryFile from "@/components/common/CustomInputGalleryFile.vue";
export default {
  name: 'WriteGallery',
  props:['changeKey'],
  components: {CustomFocusOutInput,WriteGalleryBasic,WriteGalleryThubnail,WriteGalleryGride,CustomInputGalleryFile},
  computed:{
  },
  data(){
    return {
		subInvitation:{
			invitationGalleryImagesFile:[],
			invitationGalleryBeforeImagesFile:[]
		}
    }
  },
  
  created(){
    
  },
  mounted(){
	const context = this
    EventBus.$on("invitationInit", (invitationInit) => {
		context.subInvitation =  invitationInit;
		setTimeout(function() { context.gallerySortable()}, 300);
	});


    EventBus.$on("sortableGalleryOldNew", (parameter) => {
		var items = this.subInvitation.invitationGalleryImagesFile


		const items2 = items.splice(parameter.oldIndex,1);
		items.splice(parameter.newIndex, 0, items2[0]);

		this.subInvitation.invitationGalleryImagesFile = items

		
		var items3 = this.subInvitation.invitationGalleryBeforeImagesFile


		const items4 = items3.splice(parameter.oldIndex,1);
		items3.splice(parameter.newIndex, 0, items4[0]);

		this.subInvitation.invitationGalleryBeforeImagesFile = items3

		context.changeInput()



	});
	

  },
  beforeDestroy(){
    EventBus.$off('invitationInit')
  },
  methods: {
	changeData(data){
		this.$emit('changeData',data)
		const context = this
		setTimeout(function() { context.subScript.swiperFn(); }, 100);
		setTimeout(function() { context.gallerySortable()}, 300);
	},
	changeInput(){
		this.$emit('changeData',this.subInvitation)
		const context = this
		setTimeout(function() { context.subScript.swiperFn(); }, 100);
		setTimeout(function() { context.gallerySortable()}, 300);
	},
	clickGalleryType(type){
			
		if(this.subInvitation.invitationGalleryType !== type){
			
			if((this.subInvitation.invitationGalleryType === 'basic' && type === 'thumbnail') || (this.subInvitation.invitationGalleryType === 'thumbnail' && type === 'basic')){	
				this.subInvitation.invitationGalleryType = type
				this.changeInput()
			}else{
					
				if(confirm("그리드 갤러리 타입 변경시, 이미지가 초기화됩니다. 진행하시겠습니까?")){
					this.subInvitation.invitationGalleryImagesFile = []
					this.subInvitation.invitationGalleryBeforeImagesFile = []
					
					this.subInvitation.invitationGalleryType = type
					this.changeInput()
				}
			}

		}
    	
	},
	clickGalleryTypeRatio(type){
		if(confirm("사진 비율 변경시, 이미지가 초기화됩니다. 진행하시겠습니까?")){
			this.subInvitation.invitationGalleryImagesFile = []
			this.subInvitation.invitationGalleryBeforeImagesFile = []
			this.subInvitation.invitationGalleryTypeRatio = type
			this.changeInput()
		}
	},
	clickGalleryTypeArray(type){
		this.subInvitation.invitationGalleryTypeArray = type
			this.changeInput()
	},
	changeInvitationGalleryYn(e){
		if(e.target.checked){
			this.subInvitation.invitationGalleryYn = 'Y'
		}else{

			this.subInvitation.invitationGalleryYn = 'N'
		}
			this.changeInput()
	},
	changeInvitationGalleryComplexMoreYn(e){
		if(e.target.checked){
			this.subInvitation.invitationGalleryComplexMoreYn = 'Y'
		}else{

			this.subInvitation.invitationGalleryComplexMoreYn = 'N'
		}
			this.changeInput()

	},
	uploadGalleryFile(param){
		debugger
		for(var i=0;i<param.files.length;i++){
			if(param.files[i].selectedIndex!==null && param.files[i].selectedIndex!==undefined && param.files[i].selectedIndex!==null >-1){

				this.subInvitation.invitationGalleryImagesFile[param.files[i].selectedIndex] = param.files[i]
				this.subInvitation.invitationGalleryBeforeImagesFile[param.beforeFiles[i].selectedIndex] = param.beforeFiles[i]
			}else{
				
				this.subInvitation.invitationGalleryImagesFile.push(param.files[i])
				this.subInvitation.invitationGalleryBeforeImagesFile.push(param.beforeFiles[i])
			}
		}

		
		this.changeInput()
		
	},
	async modifyFile(index){
		let files =  null;
		debugger
		if(this.subInvitation.invitationGalleryBeforeImagesFile[index].fileSeq!==null && this.subInvitation.invitationGalleryBeforeImagesFile[index].fileSeq!==undefined){

			files = [ await (this.convertURLtoFile(this.eixstIdMakeDisplayUrl(this.subInvitation.invitationGalleryBeforeImagesFile[index])))]
		}else{

			files = [ this.subInvitation.invitationGalleryBeforeImagesFile[index].file]
		}
		
    	const param = {
    	    flag: 'imagesCropPop',
    	    files:files,
    	    type:this.subInvitation.invitationGalleryType,
    	    subtype: this.subInvitation.invitationGalleryTypeRatio,
			selectedIndex: index
    	}
    	EventBus.$emit('layerPopup',param)
	},
	async convertURLtoFile(url){
		const response = await fetch(url);
		const data = await response.blob();
		const ext = url.split(".").pop(); // url 구조에 맞게 수정할 것
		const filename = url.split("/").pop(); // url 구조에 맞게 수정할 것
		const metadata = { type: `image/${ext}` };
		return new File([data], filename, metadata);
	}
  },

}
</script>
