<template>
	<div class="acco-list">
		<a href="javascript:" class="acco-tit"><span>인사말</span></a>
		<div class="acco-con">
			<section>
				<div class="acco-sec">
					<p class="tit">제목</p>
					<div class="con">
						<div class="form-input w100p">
							<CustomFocusOutInput v-model="subInvitation.invitationGreetingWordTitle" :placeholder="''" @input="changeInput"/>
						</div>
					</div>
				</div>
				<div class="acco-sec align-top">
					<p class="tit">내용</p>
					<div class="con">
						<div class="form-textarea">
							<CustomFocusOutTextarea v-model="subInvitation.invitationGreetingWordContent" :placeholder="'내용입력'" @input="changeInput"/>

						</div>
						<a class="sample-btn" @click="clickSamplePopup"><span>샘플 문구 사용하기</span></a>
					</div>
				</div>
			</section>
			<section>
				<div class="acco-sec">
					<p class="tit">표기</p>
					<div class="con">
						<div class="form-checkbox">
							<input type="checkbox" id="Chk9" name="" @change="changeInvitationGreetingNameVisibleYn" :checked="subInvitation.invitationGreetingNameVisibleYn==='Y'">
							<label for="Chk9">인사말 하단에 신랑신부&혼주 성함 표시</label>
						</div>
					</div>
				</div>
			</section>
		</div>
	</div>
</template>


<script>
import EventBus from "@/utils/CommonEventBus";
import CustomFocusOutInput from "@/components/common/CustomFocusOutInput.vue";
import CustomFocusOutTextarea from "@/components/common/CustomFocusOutTextarea.vue";

export default {
  name: 'WriteGreetingWord',
  props:[],
  components: {CustomFocusOutInput,CustomFocusOutTextarea},
  computed:{
  },
  data(){
    return {
		subInvitation:{}
    }
  },
  
  created(){
    
  },
  mounted(){
	const context = this
    EventBus.$on("invitationInit", (invitationInit) => {
		context.subInvitation =  invitationInit;
	});
    EventBus.$on("popupSave", (data) => {
		context.subInvitation.invitationGreetingWordContent = data
		context.$emit('changeData',this.subInvitation)
	});
  },
  beforeDestroy(){
    EventBus.$off('invitationInit')
    EventBus.$off('popupSave')
  },
  methods: {
	changeInput(){
		this.$emit('changeData',this.subInvitation)
	},
	changeInvitationGreetingNameVisibleYn(e){
		if(e.target.checked){
			this.subInvitation.invitationGreetingNameVisibleYn = 'Y'
		}else{

			this.subInvitation.invitationGreetingNameVisibleYn = 'N'
		}
		this.$emit('changeData',this.subInvitation)
	},
	clickSamplePopup(){
		const param = {
			flag: 'writeGreetingWordSamplePop'
		}
		EventBus.$emit('layerPopup',param)
	}

  },

}
</script>
