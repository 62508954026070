<template>
  <div class="acco-list">
		<a href="javascript:" class="acco-tit">
			<div class="form-checkbox">
				<input type="checkbox" id="Chk17" name="" @change="changeInvitationContactYn" :checked="subInvitation.invitationContactYn==='Y'">
				<label for="Chk17">연락하기</label>
			</div>
		</a>
		<div class="acco-con">
			<section>
				<div class="acco-sec">
					<p class="tit">신랑</p>
					<div class="con">
						<div class="form-input w243">
							<CustomFocusOutInput v-model="subInvitation.invitationContactGroomNumber" :placeholder="'전화번호 입력'" @input="changeInput"/>
						</div>
					</div>
				</div>
				<div class="acco-sec">
					<p class="tit">신랑 아버지</p>
					<div class="con">
						<div class="form-input w243">
							<CustomFocusOutInput v-model="subInvitation.invitationContactGroomFatherNumber" :placeholder="'전화번호 입력'" @input="changeInput"/>
						</div>
					</div>
				</div>
				<div class="acco-sec">
					<p class="tit">신랑 어머니</p>
					<div class="con">
						<div class="form-input w243">
							<CustomFocusOutInput v-model="subInvitation.invitationContactGroomMotherNumber" :placeholder="'전화번호 입력'" @input="changeInput"/>
						</div>
					</div>
				</div>
			</section>
			<section>
				<div class="acco-sec">
					<p class="tit">신부</p>
					<div class="con">
						<div class="form-input w243">
							<CustomFocusOutInput v-model="subInvitation.invitationContactBrideNumber" :placeholder="'전화번호 입력'" @input="changeInput"/>
						</div>
					</div>
				</div>
				<div class="acco-sec">
					<p class="tit">신부 아버지</p>
					<div class="con">
						<div class="form-input w243">
							<CustomFocusOutInput v-model="subInvitation.invitationContactBrideFatherNumber" :placeholder="'전화번호 입력'" @input="changeInput"/>
						</div>
					</div>
				</div>
				<div class="acco-sec">
					<p class="tit">신부 어머니</p>
					<div class="con">
						<div class="form-input w243">
							<CustomFocusOutInput v-model="subInvitation.invitationContactBrideMotherNumber" :placeholder="'전화번호 입력'" @input="changeInput"/>
						</div>
					</div>
				</div>
			</section>
			<div class="info-box">
				<p>전화번호가 입력된 항목만 표시됩니다.</p>
			</div>
		</div>
	</div>	
</template>


<script>
import EventBus from "@/utils/CommonEventBus";
import CustomFocusOutInput from "@/components/common/CustomFocusOutInput.vue";
export default {
  name: 'WriteContact',
  components: {CustomFocusOutInput},
  computed:{
  },
  data(){
    return {
		subInvitation:{}
    }
  },
  
  created(){
    
  },
  mounted(){
	const context = this
    EventBus.$on("invitationInit", (invitationInit) => {
		context.subInvitation =  invitationInit;
	});
  },
  beforeDestroy(){
    EventBus.$off('invitationInit')
  },
  methods: {
	changeInput(){
		this.$emit('changeData',this.subInvitation)
	},
	changeInvitationContactYn(e){
		if(e.target.checked){
			this.subInvitation.invitationContactYn = 'Y'
		}else{

			this.subInvitation.invitationContactYn = 'N'
		}
		this.$emit('changeData',this.subInvitation)

	}

  },

}
</script>
