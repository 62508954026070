<template>
	<div class="img-view type2 array2" :key="changeKey">
			<div class="file-img-area" v-for="(invitationGalleryImageFile,index) in subInvitation.invitationGalleryImagesFile" :key="index+changeKey">
				<img :src="eixstIdMakeDisplayUrl(invitationGalleryImageFile)"  alt="">
				<button type="button" class="delete-btn" @click="deleteInvitationGalleryImageFile(index)"></button>
				<button type="button" class="edit-btn"  @click="modifyInvitationGalleryImageFile(index)"><span>썸네일 편집</span></button>
			</div>
	</div>
</template>


<script>
import EventBus from "@/utils/CommonEventBus";
export default {
	props:['changeKey'],
  name: 'WriteGalleryThubnail',
  components: {},
  computed:{
  },
  data(){
    return {
		subInvitation:{},
    }
  },
  
  created(){
  },
  mounted(){
	const context = this
    EventBus.$on("invitationInit", (invitationInit) => {
		context.subInvitation =  invitationInit;

	});
  },
  beforeDestroy(){
    EventBus.$off('invitationInit')
  },
  methods: {
	changeInput(){
		this.$emit('changeData',this.subInvitation)
	},
	deleteInvitationGalleryImageFile(index){
		this.subInvitation.invitationGalleryImagesFile.splice(index,1)
		this.subInvitation.invitationGalleryBeforeImagesFile.splice(index,1)
		this.changeInput()
	},
	modifyInvitationGalleryImageFile(index){
		this.$emit('modifyFile',index);
	}
  },

}
</script>
